import React from "react";
import { RotatingLines, Bars, ColorRing } from "react-loader-spinner";
import { ScaleLoader } from "react-spinners";
function FormLoader({ isLoading }) {
  return (
    <div>
      <div
      // style={{
      //   display: "flex",
      //   alignItems: "center",
      //   justifyContent: "center",
      // }}
      >
        {/* <RotatingLines
          strokeColor="#d6e841" 
          strokeWidth="5"
          animationDuration="0.75"
          width="100"
          visible={isLoading}
        /> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin : "20%"
          }}
        >
          {/* <Bars
            height="80"
            width="80"
            color="#747B26"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          /> */}

<ColorRing
  visible={true}
  height="100"
  width="100"
  ariaLabel="blocks-loading"
  wrapperStyle={{}}
  wrapperClass="blocks-wrapper"
  colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
/>
        </div>

        {/* <ScaleLoader  
        color={"#004280"}
        loading={isLoading}
        style={{
          display: "block",
          textAlign: "center",
          margin: "20%",
          borderColor: "#b76b29",
        }}
        width="100"
        // css={override}
        // size={300}
      /> */}
      </div>
    </div>
  );
}

export default FormLoader;
