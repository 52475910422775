export const BASE_URL = "https://api.deenlearning.in:3006/";
const BASE_URL_download = "https://api.deenlearning.in:3006/";
const NOORANIQAIDAH = "nooraniQaidah/"
const ApiConfig = {
  BASE_URL_download,
  BASE_URL_FOR_IMAGES: "https://api.deenlearning.in:3006/",
  BASE_URL: BASE_URL,
  LOGIN: BASE_URL + "login",
  DASHBOARD: BASE_URL + "dashboard/appContentCount",
  SUBAQADD: BASE_URL + NOORANIQAIDAH + "addSabaq",
  SUBAQUPDATENORANI: BASE_URL + NOORANIQAIDAH + "updateSabaq",
  SUBAQADDHIFZ: BASE_URL + "hifzeSurah/addSurah",
  SUBAQUPDATHIFZ: BASE_URL + "hifzeSurah/updateSurah",
  SUBAQADDALL: BASE_URL + "addSabaq",
  SUBAQUPDATE: BASE_URL + "updateSabaq/",
  SUBAQLIST: BASE_URL + "sabaqList",
  EDIT: BASE_URL + NOORANIQAIDAH + "subSabaqList",
  EDITALL: BASE_URL + "subSabaqList",
  EDITHIFZ: BASE_URL + "hifzeSurah/ayahList",
  DELETEAPI: BASE_URL + "deleteSabaq",
  NOTIFICATION: BASE_URL + "notification/",
  ADDINTRO: BASE_URL + "dashboard/addIntro",
  UPDATEINTRO: BASE_URL + "dashboard/updateIntro",
  LISTINTRO: BASE_URL + "dashboard/getIntro",
  VIEWINTRO: BASE_URL + "dashboard/getIntroAdmin?introFileId=",
  DELETEINTRO: BASE_URL + "dashboard/deleteIntro?introFileId=",
  DAILYPOST: BASE_URL + "dailypost/addPost",
  LISTDAILYPOST: BASE_URL + "dailypost/getPost",
  VIEWDAILYPOST: BASE_URL + "dailypost/getPost?postId=",
  UPDATEDAILYPOST: BASE_URL + "dailypost/updatePost",
  DELETEDAILYPOST: BASE_URL + "dailypost/deletePost?postId="




};
export default ApiConfig;
