import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "./../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";

import ic_delete from "../assets/images/ic_delete.svg";
import ic_edit from "../assets/images/ic_edit.svg";
import ic_eyes from "../assets/images/ic_eyes.svg";
import Modal from "react-bootstrap/Modal";
import { ToastMsg } from "./TostMsg";
import ApiConfig from "../api/ApiConfig";
import {
  DeleteCallWithErrorResponse,
  multipartPostCallWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../api/ApiServices";
import { Pagination } from "./Pagination";
import FormLoader from "./FormLoader";
const Seerat = () => {
  const { sidebar, adminData } = useContext(AppContext);
  const [loader, setLoader] = useState(true);
  const [idEdit, setIdEdit] = useState("");
  const [imageWidth, setImageWidth] = useState({
    sabaqAudio: "100%",
    Uploade: "100%",
    Tarjama: "100%",
    Description: "100%",
    subSabaqTitle: "100%",
  });
  const [seeratList, setSeeratList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const startIndex = currentPage * 15;
  const endIndex = startIndex + 15;
  const changePage = ({ selected }) => {
    setCurrentPage(selected);
  };
  const pageCount = Math.ceil(seeratList?.length / 15);
  const [seerat, setSeerat] = useState({
    sabaqName: "",
    sabaqDesc: "",
    sabaqNameAudio: "",
    sabaqDescAudio: "",
    subSabaq: [
      {
        subSabaqTitle: "",
        subSabaqTitleAudio: "",
        subSabaqDesc: "",
        subSabaqDescAudio: "",
      },
    ],
  });
  const [viewDetails, setViewDetails] = useState([]);
  const [add, setAdd] = useState(false);
  const handleAddClose = () => {
    setSeerat({
      sabaqName: "",
      sabaqDesc: "",
      sabaqNameAudio: "",
      sabaqDescAudio: "",
      subSabaq: [
        {
          subSabaqTitle: "",
          subSabaqDesc: "",
          subSabaqTitleAudio: "",
          subSabaqDescAudio: "",
        },
      ],
    });
    setIdEdit("");
    setAdd(false);
  };
  const handleAddShow = () => setAdd(true);
  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDelete] = useState(false);
  const handleDeleteClose = () => setDelete(false);
  const handleDeleteShow = () => setDelete(true);
  const [lasatSabaqId, setLsatSabaq] = useState("");
  const [saveBotton, setSaveBotton] = useState(false);

  const [viewModal, setView] = useState(false);
  const handleViewClose = () => setView(false);
  const handleViewShow = () => setView(true);

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      addSeeratApi();
    }
    setValidated(true);
  };
  //add
  const addSeeratApi = () => {
    setSaveBotton(true);
    handleAddClose();
    setLoader(true);
    const body = {
      categoryId: 9,
      userId: Number(adminData.user_id),
      sabaqNo: lasatSabaqId + 1,
      sabaqName: seerat.sabaqName ? seerat.sabaqName : "",
      sabaqDesc: seerat.sabaqDesc ? seerat.sabaqDesc : "",
      sabaqId: seerat.SabaqId,
      //  sabqId:lasatSabaqId
    };
    //all dataa paylodade

    let payLoadFormData = new FormData();
    payLoadFormData.append("categoryId", body.categoryId);
    payLoadFormData.append("userId", body.userId);
    payLoadFormData.append("sabaqName", body.sabaqName);
    payLoadFormData.append("sabaqDesc", body.sabaqDesc);
    idEdit === "edit"
      ? payLoadFormData.append("sabaqId", body.sabaqId)
      : payLoadFormData.append("sabaqNo", body.sabaqNo);
    // payLoadFormData.append("sabaqNo", body.sabaqNo)
    let subSabaq = seerat.subSabaq.map((ele, index) => {
      return {
        subSabaqDesc: ele.subSabaqDesc ? ele.subSabaqDesc : "",
        subSabaqNo: index + 1,
        subSabaqTitle: ele.subSabaqTitle ? ele.subSabaqTitle : "",
        subSabaqTitleAudio:
          ele.subSabaqTitleAudio == "" ? "no file" : "file name",
        subSabaqDescAudio:
          ele.subSabaqDescAudio == "" ? "no file" : "file name",
      };
    });

    let subSabaqEdit = seerat.subSabaq.map((ele, index) => {
      return {
        subSabaqDesc: ele.subSabaqDesc ? ele.subSabaqDesc : "",
        subSabaqNo: index + 1,
        subSabaqId: ele.subSabaqId,
        subSabaqTitle: ele.subSabaqTitle ? ele.subSabaqTitle : "",
        subSabaqTitleAudio:
          typeof ele.subSabaqTitleAudio == "object" ? "updated" : "not updated",
        subSabaqDescAudio:
          typeof ele.subSabaqDescAudio == "object" ? "updated" : "not updated",
      };
    });
    idEdit === "edit"
      ? payLoadFormData.append("subSabaq", JSON.stringify(subSabaqEdit))
      : payLoadFormData.append("subSabaq", JSON.stringify(subSabaq));

    let newSubaqAudio = [];
    newSubaqAudio.push(seerat.sabaqNameAudio);
    newSubaqAudio.map((ele) =>
      payLoadFormData.append("sabaqNameAudio", ele ? ele : "")
    );
    //

    let newSubaqAudio2 = [];
    newSubaqAudio2.push(seerat.sabaqDescAudio);
    newSubaqAudio2.map((ele) =>
      payLoadFormData.append("sabaqDescAudio", ele ? ele : "")
    );

    // console.log(seerat.subSabaq, "xvccagcgahs");
    seerat.subSabaq.map((ele) =>
      payLoadFormData.append(
        "subSabaqDescAudio",
        ele.subSabaqDescAudio ? ele.subSabaqDescAudio : ""
      )
    );

    seerat.subSabaq.map((ele) =>
      payLoadFormData.append(
        "subSabaqTitleAudio",
        ele.subSabaqTitleAudio ? ele.subSabaqTitleAudio : ""
      )
    );

    // setIsLoading(true);
    idEdit === "edit"
      ? putMultipartWithAuthCallWithErrorResponse(
          ApiConfig.SUBAQUPDATE,
          payLoadFormData
        )
          .then((res) => {
            if (res.json && res.json?.success === true) {
              ToastMsg("success", res.json.message);
              seeratListApi();
              setSaveBotton(false);
            } else {
              ToastMsg("error", res.json.message);
            }
          })
          .catch((err) => {
            setLoader(false);

            ToastMsg("error", "something went wrong");
          })
      : multipartPostCallWithErrorResponse(
          ApiConfig.SUBAQADDALL,
          payLoadFormData
          // payLoadFormDataNew
        )
          .then((res) => {
            if (res.json && res.json?.success === true) {
              ToastMsg("success", res.json.message);
              seeratListApi();
              setSaveBotton(false);
            } else {
              ToastMsg("error", res.json.message);
            }
          })
          .catch((err) => {
            setLoader(false);

            ToastMsg("error", "something went wrong");
          });
  };
  //  list
  useEffect(() => {
    seeratListApi();
  }, []);
  const seeratListApi = () => {
    simpleGetCallWithErrorResponse(ApiConfig.SUBAQLIST + `?categoryId=${9}`)
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          setSeeratList(res.json.data?.allSabaq);
          setLsatSabaq(res.json?.data.allSabaq.length);
          // pagination();
          // ToastMsg("success", res.json.message);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  const seeratLisDeleteApi = () => {
    const body = {
      categoryId: 9,

      sabaqId: deleteId,
    };
    // setIsLoading(true);
    DeleteCallWithErrorResponse(
      ApiConfig.DELETEAPI,

      JSON.stringify({
        ...body,
      })
    )
      .then((res) => {
        setLoader(false);
        handleDeleteClose();
        if (res.json && res.json?.success === true) {
          seeratListApi();

          ToastMsg("success", res.json.message);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };
  //

  const EditListApi = (id) => {
    simpleGetCallWithErrorResponse(ApiConfig.EDITALL + `?sabaqId=${id}`)
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          // ToastMsg("success", res.json.message);
          let data = res.json?.data;
          setSeerat({
            SabaqId: data?.SabaqId,
            sabaqName: data?.sabaqName ? data?.sabaqName : "",
            sabaqDesc: data?.sabaqDesc ? data?.sabaqDesc : "",
            sabaqDescAudio: data?.sabaqDescAudio ? data?.sabaqDescAudio : "",
            sabaqNameAudio: data?.sabaqAudio ? data?.sabaqAudio : "",
            subSabaq:
              data &&
              data.subSabaqs.map((ele, index) => {
                return {
                  subSabaqTitle: ele.subSabaqTitle ? ele.subSabaqTitle : "",
                  subSabaqTitleAudio: ele.titleAudioPath
                    ? ele.titleAudioPath
                    : "",
                  subSabaqDesc: ele.subSabaqDesc ? ele.subSabaqDesc : "",
                  subSabaqDescAudio: ele.descAudioPath ? ele.descAudioPath : "",
                  subSabaqId: ele.subSabaqId,
                  subSabaqNo: ele.subSabaqNo ? ele.subSabaqNo : "",
                };
              }),
          });

          handleAddShow();
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  //view the
  const viewDetailshandle = (id) => {
    simpleGetCallWithErrorResponse(ApiConfig.EDITALL + `?sabaqId=${id}`)
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          setViewDetails(res.json.data);
          handleViewShow();
          // ToastMsg("success", res.json.message);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Seerat</label>
        </div>
        <div className="add-btn">
          <button onClick={handleAddShow}>
            Add Sabaq
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path fill="white" d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" />
            </svg>
          </button>
        </div>
      </div>
      {/* ======================= START ADD ========================== */}
      <Modal
        show={add}
        // onHide={handleAddClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton onHide={handleAddClose}>
          <Modal.Title>
            {idEdit === "edit" ? "Update  Seerat" : "Add Seerat"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12 mb-3">
                <Form.Label>Sabaq Name</Form.Label>
                <Form.Control
                  value={seerat?.sabaqName}
                  type="text"
                  required
                  onChange={(e) =>
                    setSeerat({ ...seerat, sabaqName: e.target.value })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Sabaq Name
                </Form.Control.Feedback>
              </div>

              {idEdit ? (
                <>
                  <div className="row d-flex">
                    <div className="col-md-12">
                      <label htmlFor="" className="key">
                        Sabaq Audio
                      </label>
                    </div>
                    <div
                      className={imageWidth === "100%" ? "d-none" : "col-md-6 "}
                    >
                      <Form.Label>
                        {typeof seerat?.sabaqNameAudio === "string"
                          ? seerat?.sabaqNameAudio.substring(
                              seerat?.sabaqNameAudio.length - 15,
                              seerat?.sabaqNameAudio.length
                            )
                          : ""}
                      </Form.Label>
                    </div>
                    <div className="col-md-12 mb-3 ">
                      <Form.Control
                        type="file"
                        accept="audio/mp3,audio/*;capture=microphone"
                        name="sabaqAudio"
                        style={{ width: imageWidth.sabaqAudio }}
                        onChange={(e) => {
                          setImageWidth({ ...imageWidth, sabaqAudio: "100%" });
                          setSeerat({
                            ...seerat,
                            sabaqNameAudio: e.target.files[0],
                          });
                        }}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Name
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-md-12 mb-3">
                  <Form.Label>Sabaq Audio</Form.Label>
                  <Form.Control
                    type="file"
                    accept="audio/mp3,audio/*;capture=microphone"
                    name="sabaqAudio"
                    //  / value={addSabaq?.sabaqAudio}
                    onChange={(e) =>
                      setSeerat({
                        ...seerat,
                        sabaqNameAudio: e.target.files[0],
                      })
                    }
                  />

                  <Form.Control.Feedback type="invalid">
                    Please Enter Sabaq Name
                  </Form.Control.Feedback>
                </div>
              )}

              <div className="col-md-12 mb-3">
                <Form.Label>Sabaq Description .</Form.Label>
                <Form.Control
                  as="textarea"
                  value={seerat?.sabaqDesc}
                  rows={3}
                  onChange={(e) =>
                    setSeerat({ ...seerat, sabaqDesc: e.target.value })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Seerat Description.
                </Form.Control.Feedback>
              </div>

              {idEdit ? (
                <>
                  <div className="row d-flex">
                    <div className="col-md-12">
                      <label htmlFor="" className="key">
                        Sabaq Description Audio
                      </label>
                    </div>
                    <div
                      className={imageWidth === "100%" ? "d-none" : "col-md-6 "}
                    >
                      <Form.Label>
                        {typeof seerat?.sabaqDescAudio === "string"
                          ? seerat?.sabaqDescAudio.substring(
                              seerat?.sabaqDescAudio.length - 15,
                              seerat?.sabaqDescAudio.length
                            )
                          : ""}
                      </Form.Label>
                    </div>
                    <div className="col-md-12 mb-3 ">
                      <Form.Control
                        type="file"
                        accept="audio/mp3,audio/*;capture=microphone"
                        name="sabaqDescAudio"
                        style={{ width: imageWidth.Uploade }}
                        onChange={(e) => {
                          setImageWidth({ ...imageWidth, Uploade: "100%" });
                          setSeerat({
                            ...seerat,
                            sabaqDescAudio: e.target.files[0],
                          });
                        }}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Name
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-md-12 mb-3">
                  <Form.Label>Sabaq Description Audio</Form.Label>
                  <Form.Control
                    type="file"
                    accept="audio/mp3,audio/*;capture=microphone"
                    name="sabaqImagePath"
                    //  / value={addSabaq?.sabaqAudio}
                    onChange={(e) =>
                      setSeerat({
                        ...seerat,
                        sabaqDescAudio: e.target.files[0],
                      })
                    }
                  />

                  <Form.Control.Feedback type="invalid">
                    Please Enter Sabaq Name
                  </Form.Control.Feedback>
                </div>
              )}
              {seerat.subSabaq &&
                seerat.subSabaq.map((elementInArray, index) => {
                  return (
                    <>
                      <div
                        className="col-md-12 sub-group-main"
                        key={"testkey" + index}
                      >
                        <Form.Label> Sub Sabaq Title {index + 1}</Form.Label>
                        <Form.Control
                          value={elementInArray?.subSabaqTitle}
                          type="text"
                          onChange={(e) => {
                            setSeerat({
                              ...seerat,
                              subSabaq: seerat.subSabaq.map(
                                (item, innerIndex) => {
                                  return index === innerIndex
                                    ? {
                                        ...item,
                                        subSabaqTitle: e.target.value,
                                      }
                                    : item;
                                }
                              ),
                            });
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Sabaq Name
                        </Form.Control.Feedback>

                        {idEdit ? (
                          <>
                            <div className="row d-flex">
                              <div className="col-md-12">
                                <label htmlFor="" className="key">
                                  Sub Sabaq Title Audio
                                </label>
                              </div>
                              <div
                                className={
                                  imageWidth === "100%" ? "d-none" : "col-md-6 "
                                }
                              >
                                <Form.Label>
                                  {typeof elementInArray?.subSabaqTitleAudio ===
                                  "string"
                                    ? elementInArray?.subSabaqTitleAudio.substring(
                                        elementInArray?.subSabaqTitleAudio
                                          .length - 15,
                                        elementInArray?.subSabaqTitleAudio
                                          .length
                                      )
                                    : ""}
                                </Form.Label>
                              </div>
                              <div className="col-md-12 mb-3 ">
                                <Form.Control
                                  type="file"
                                  accept="audio/mp3,audio/*;capture=microphone"
                                  name="subSabaqTitleAudio"
                                  style={{ width: imageWidth.subSabaqTitle }}
                                  onChange={(e) => {
                                    setImageWidth({
                                      ...imageWidth,
                                      subSabaqTitle: "100%",
                                    });
                                    setSeerat({
                                      ...seerat,
                                      subSabaq: seerat.subSabaq.map(
                                        (item, innerIndex) => {
                                          return index === innerIndex
                                            ? {
                                                ...item,
                                                subSabaqTitleAudio:
                                                  e.target.files[0],
                                              }
                                            : item;
                                        }
                                      ),
                                    });
                                  }}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Sabaq Name
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-12 mb-3">
                            <Form.Label> Sub Sabaq Title Audio</Form.Label>
                            <Form.Control
                              type="file"
                              accept="audio/mp3,audio/*;capture=microphone"
                              name="sabaqImagePath"
                              //  / value={addSabaq?.sabaqAudio}
                              onChange={(e) => {
                                setSeerat({
                                  ...seerat,
                                  subSabaq: seerat.subSabaq.map(
                                    (item, innerIndex) => {
                                      return index === innerIndex
                                        ? {
                                            ...item,
                                            subSabaqTitleAudio:
                                              e.target.files[0],
                                          }
                                        : item;
                                    }
                                  ),
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Sabaq Name
                            </Form.Control.Feedback>
                          </div>
                        )}
                        <div className="col-md-12 mb-3">
                          <Form.Label> Sub Sabaq Description</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={elementInArray?.subSabaqDesc}
                            onChange={(e) => {
                              setSeerat({
                                ...seerat,
                                subSabaq: seerat.subSabaq.map(
                                  (item, innerIndex) => {
                                    return index === innerIndex
                                      ? {
                                          ...item,
                                          subSabaqDesc: e.target.value,
                                        }
                                      : item;
                                  }
                                ),
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Seerat Description .
                          </Form.Control.Feedback>
                        </div>

                        <>
                          {idEdit ? (
                            <>
                              <div className="row d-flex">
                                <div className="col-md-12">
                                  <label htmlFor="" className="key">
                                    Sub Sabaq Description Audio
                                  </label>
                                </div>
                                <div
                                  className={
                                    imageWidth === "100%"
                                      ? "d-none"
                                      : "col-md-6 "
                                  }
                                >
                                  <Form.Label>
                                    {typeof elementInArray?.subSabaqDescAudio ===
                                    "string"
                                      ? elementInArray?.subSabaqDescAudio.substring(
                                          elementInArray?.subSabaqDescAudio
                                            .length - 15,
                                          elementInArray?.subSabaqDescAudio
                                            .length
                                        )
                                      : ""}
                                  </Form.Label>
                                </div>
                                <div className="col-md-12 mb-3 ">
                                  <Form.Control
                                    type="file"
                                    accept="audio/mp3,audio/*;capture=microphone"
                                    name="sabaqImagePath"
                                    style={{ width: imageWidth.Description }}
                                    onChange={(e) => {
                                      setImageWidth({
                                        ...imageWidth,
                                        Description: "100%",
                                      });
                                      setSeerat({
                                        ...seerat,
                                        subSabaq: seerat.subSabaq.map(
                                          (item, innerIndex) => {
                                            return index === innerIndex
                                              ? {
                                                  ...item,
                                                  subSabaqDescAudio:
                                                    e.target.files[0],
                                                }
                                              : item;
                                          }
                                        ),
                                      });
                                    }}
                                  />

                                  <Form.Control.Feedback type="invalid">
                                    Please Enter Sabaq Name
                                  </Form.Control.Feedback>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="col-md-12 mb-3">
                              <Form.Label>
                                {" "}
                                Sub Sabaq Description Audio
                              </Form.Label>
                              <Form.Control
                                type="file"
                                accept="audio/mp3,audio/*;capture=microphone"
                                name="sabaqImagePath"
                                //  / value={addSabaq?.sabaqAudio}
                                onChange={(e) => {
                                  setSeerat({
                                    ...seerat,
                                    subSabaq: seerat.subSabaq.map(
                                      (item, innerIndex) => {
                                        return index === innerIndex
                                          ? {
                                              ...item,
                                              subSabaqDescAudio:
                                                e.target.files[0],
                                            }
                                          : item;
                                      }
                                    ),
                                  });
                                }}
                              />

                              <Form.Control.Feedback type="invalid">
                                Please Enter Sabaq Name
                              </Form.Control.Feedback>
                            </div>
                          )}
                        </>
                        <div className=" text-end mb-3">
                          <Link
                            to="#"
                            // className="btn btn-danger"
                            // onClick={() =>
                            //   setaddDescription(addDescription - 1)
                            // }
                            className={
                              seerat.subSabaq.length > 1
                                ? "btn btn-danger"
                                : "btn btn-secondary invisible"
                            }
                            onClick={() => {
                              if (seerat.subSabaq.length > 1) {
                                // seerat.subSabaq.splice(index, 1);
                                const copySubDeatails = [...seerat.subSabaq];
                                copySubDeatails.splice(index, 1);
                                setSeerat({
                                  ...seerat,
                                  subSabaq: copySubDeatails,
                                });
                              }

                              // const copySubDeatails = [...addSabaq.subsabaqDetails];
                              // copySubDeatails.sli
                            }}
                          >
                            Remove
                          </Link>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>

            <Link
              to="#"
              className={
                // idEdit
                //   ? "add-newSabaq-btn invisible btn btn-secondary pe-none"
                //   :
                "add-newSabaq-btn btn btn-primary"
              }
              // onClick={() => setaddDescription(addDescription + 1)}
              onClick={() => {
                let sirat = [
                  ...seerat.subSabaq,

                  {
                    subSabaqTitle: "",
                    subSabaqDesc: "",
                    subSabaqTitleAudio: "",
                    subSabaqDescAudio: "",
                  },
                ];
                setSeerat({
                  ...seerat,
                  subSabaq: sirat,
                });
              }}
            >
              {" "}
              + Add Sub Sabaq Details
            </Link>
            <div className="buttons-modal border-top pt-3 text-end ">
              <button className="me-2" type="submit" disabled={saveBotton}>
                {idEdit === "edit" ? "update" : "Save"}
              </button>
              <Link
                to="#"
                className=" bg-danger"
                onClick={() => {
                  setSeerat({
                    sabaqName: "",
                    sabaqNameAudio: "",
                    subSabaq: [
                      {
                        subSabaqTitle: "",
                        subSabaqDesc: "",
                        subSabaqTitleAudio: "",
                        subSabaqDescAudio: "",
                      },
                    ],
                  });
                  handleAddClose();
                  setIdEdit("");
                }}
              >
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END ADD ========================== */}

      {/* ======================= DELETE ========================== */}

      <Modal
        show={deleteModal}
        onHide={handleDeleteClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">Are you sure you want to delete this item?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button
              className="me-2 bg-danger"
              type="submit"
              onClick={() => {
                handleDeleteClose();
                seeratLisDeleteApi();
              }}
            >
              Delete
            </button>
            <Link to="#" className="" onClick={handleDeleteClose}>
              Cancel
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
      {/* ======================= END DELETE ========================== */}

      {/* ======================= VIEW ========================== */}
      <Modal
        show={viewModal}
        onHide={handleViewClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Sabaq Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Sabaq No.
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewDetails.SabaqId}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Sabaq Name.
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewDetails.sabaqName}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="mb-2">
                  <label htmlFor="" className="key">
                    Sabaq Audio.
                  </label>
                </div>
                <div>
                  <audio controls style={{ width: "100%", height: "38px" }}>
                    <source src={viewDetails.sabaqAudio} type="audio/ogg" />
                    <source src={viewDetails.sabaqAudio} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Sabaq Description.
                  </label>
                </div>

                <div>
                  <label htmlFor="" className="value">
                    {viewDetails?.sabaqDesc}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="mb-2">
                  <label htmlFor="" className="key">
                    Sabaq Description Audio
                  </label>
                </div>
                <div>
                  <audio controls style={{ width: "100%", height: "38px" }}>
                    <source src={viewDetails.sabaqDescAudio} type="audio/ogg" />
                    <source
                      src={viewDetails.sabaqDescAudio}
                      type="audio/mpeg"
                    />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              </div>

              <h5 className="fw-bold">Sub Sabaq Details</h5>

              {viewDetails &&
                viewDetails.subSabaqs &&
                viewDetails.subSabaqs.map((ele, index) => {
                  return (
                    <div
                      className="sub-group-main row"
                      key={"testkey121" + index}
                    >
                      <div className="col-md-6 mb-3">
                        <div>
                          <label htmlFor="" className="key">
                            sub Sabaq Title {ele.subSabaqNo}
                          </label>
                        </div>
                        <div>
                          <label htmlFor="" className="value">
                            {ele.subSabaqTitle}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="mb-2">
                          <label htmlFor="" className="key">
                            sub Sabaq Title Audio
                          </label>
                        </div>
                        <div>
                          <audio
                            controls
                            style={{ width: "100%", height: "38px" }}
                          >
                            <source src={ele.titleAudioPath} type="audio/ogg" />
                            <source
                              src={ele.titleAudioPath}
                              type="audio/mpeg"
                            />
                            Your browser does not support the audio element.
                          </audio>
                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <div>
                          <label htmlFor="" className="key">
                            sub Sabaq Description
                          </label>
                        </div>
                        <div>
                          <label htmlFor="" className="value">
                            {ele.subSabaqDesc}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="mb-2">
                          <label htmlFor="" className="key">
                            sub Sabaq Description Audio
                          </label>
                        </div>
                        <div>
                          <audio
                            controls
                            style={{ width: "100%", height: "38px" }}
                          >
                            <source src={ele.descAudioPath} type="audio/ogg" />
                            <source src={ele.descAudioPath} type="audio/mpeg" />
                            Your browser does not support the audio element.
                          </audio>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              {/* <button className="me-2" type="submit">
                Save
              </button> */}
              <Link to="#" className="bg-danger" onClick={handleViewClose}>
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END VIEW ========================== */}

      {loader ? (
        <FormLoader />
      ) : (
        <div className="table-wrapper">
          <h6>Total Records : {seeratList.length}</h6>
          <Table className="display" striped bordered hover size="lg">
            <thead>
              <tr>
                <th>Sabaq No.</th>
                <th style={{ textAlign: "left" }}>Seerat List</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {seeratList && seeratList.length > 0
                ? seeratList.slice(startIndex, endIndex).map((ele, index) => {
                    return (
                      <tr key={"testkeylist" + index}>
                        <td>{index + 1 + startIndex}</td>

                        <td style={{ textAlign: "left" }}>{ele.sabaqName}</td>
                        <td className="action">
                          <Link
                            to="#"
                            className="icon edit"
                            onClick={() => {
                              // handleAddShow();
                              setIdEdit("edit");
                              setImageWidth({
                                ...imageWidth,
                                sabaqAudio: "117px",
                                Uploade: "117px",
                                Tarjama: "117px",
                                Description: "117px",
                                subSabaqTitle: "117px",
                              });
                              EditListApi(ele.sabaqId);
                            }}
                          >
                            <img src={ic_edit} alt="" />
                          </Link>
                          <Link
                            to="#"
                            className="icon view"
                            onClick={() => viewDetailshandle(ele.sabaqId)}
                          >
                            <img src={ic_eyes} alt="" />
                          </Link>
                          <Link
                            to="#"
                            className="icon delete"
                            onClick={() => {
                              handleDeleteShow();
                              setDeleteId(ele.sabaqId);
                            }}
                          >
                            <img src={ic_delete} alt="" />
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                : ""}
            </tbody>
          </Table>
          {seeratList && seeratList.length > 0 ? (
            <Pagination
              changePage={changePage}
              pageCount={pageCount}
              currentPage={currentPage}
            />
          ) : (
            <div>
              <h5 style={{ color: "red", textAlign: "center" }}>
                {" "}
                Records Not Found
              </h5>
            </div>
          )}
        </div>
      )}
    </main>
  );
};

export default Seerat;
