import React from "react";
import ReactPaginate from "react-paginate";
export const Pagination = ({  pageCount, changePage, currentPage }) => {
 
  return (
    <div className="row mt-4">
      {" "}
      <div className="col-lg-12">
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
            forcePage={currentPage}
        />
      </div>
    </div>
  );
};
