import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";

import ic_delete from "../assets/images/ic_delete.svg";
import ic_edit from "../assets/images/ic_edit.svg";
import ic_eyes from "../assets/images/ic_eyes.svg";
import Modal from "react-bootstrap/Modal";
import { ToastMsg } from "./TostMsg";
import ApiConfig from "../api/ApiConfig";
import {
    DeleteCallWithErrorResponse,
    multipartPostCallWithErrorResponse,
    PostCallWithAuthTokenWithErrorResponse,
    putMultipartWithAuthCallWithErrorResponse,
    simpleGetCallWithErrorResponse,
} from "../api/ApiServices";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Pagination } from "./Pagination";
import FormLoader from "./FormLoader";

const Intro = () => {
    const [AddNewGroup, setAddNewGroup] = useState(0);
    const [idEdit, setIdEdit] = useState("");
    const { sidebar, adminData } = useContext(AppContext);
    const [loader, setLoader] = useState(true);
    const [viewDetails, setViewDetails] = useState({});
    const [introId, setIntroId] = useState("")
    const [imageWidth, setImageWidth] = useState({
        sabaqAudio: "100%",
        Uploade: "100%",
        Uploade1: "100%",
        Tarjama: "100%",
        Description: "100%",
        subSabaqTitle: "100%",
    });
    const [addNamaz, setAddNamaz] = useState({
        intro_file_title: "",
        intro_file_desc: "",
        intro_file_path: "",
        intro_file_id: "",
        intro_file_duration: "",

    });

    const [add, setAdd] = useState(false);
    const handleAddClose = () => {
        setIdEdit("");
        setAddNamaz({
            intro_file_title: "",
            intro_file_desc: "",
            intro_file_path: "",
            // introId: null
        });
        setAdd(false);
    };
    const handleAddShow = () => setAdd(true);
    const [lasatSabaqId, setLsatSabaq] = useState("");
    const [saveBotton, setSaveBotton] = useState(false);




    const [deleteId, setDeleteId] = useState("");
    const [deleteModal, setDelete] = useState(false);
    const handleDeleteClose = () => setDelete(false);
    const handleDeleteShow = () => setDelete(true);

    const [viewModal, setView] = useState(false);
    const handleViewClose = () => setView(false);
    const handleViewShow = () => setView(true);

    const [validated, setValidated] = useState(false);
    const [masaiList, setMasaiList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const startIndex = currentPage * 15;
    const endIndex = startIndex + 15;
    const changePage = ({ selected }) => {
        setCurrentPage(selected);
    };
    const pageCount = Math.ceil(masaiList?.length / 15);
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            addSabaqApi();
        }

        setValidated(true);
    };

    //add
    const addSabaqApi = () => {
        // setSaveBotton(true)
        handleAddClose();
        setLoader(true);
        const body = {
            introFileId: addNamaz.intro_file_id ? addNamaz.intro_file_id : "",
            introFileTitle: addNamaz.intro_file_title ? addNamaz.intro_file_title : "",
            introFileDesc: addNamaz.intro_file_desc ? addNamaz.intro_file_desc : "",
            introFilePath: addNamaz.intro_file_path,
            introFileDuration: addNamaz.intro_file_duration ? addNamaz.intro_file_duration : "",

        };
        let payLoadFormData = new FormData();
        payLoadFormData.append("introFileTitle", body.introFileTitle);
        payLoadFormData.append("introFileDesc", body.introFileDesc);
        payLoadFormData.append("introFilePath", body.introFilePath);
        // eslint-disable-next-line no-unused-expressions
        idEdit === "edit" ?
            payLoadFormData.append("introFileId", body.introFileId) ||
            payLoadFormData.append("introFileDuration", body.introFileDuration) : "",



            setLoader(true);

        idEdit === "edit"
            ? putMultipartWithAuthCallWithErrorResponse(
                ApiConfig.UPDATEINTRO,
                payLoadFormData
            )
                .then((res) => {
                    if (res.json && res.json?.success === true) {
                        ToastMsg("success", res.json.message);
                        ramzanListApi();
                        // setSaveBotton(false)
                    } else {
                        ToastMsg("error", res.json.message);
                    }
                })
                .catch((err) => {
                    setLoader(false);

                    ToastMsg("error", "something went wrong");
                })
            : multipartPostCallWithErrorResponse(
                ApiConfig.ADDINTRO,
                payLoadFormData
                // payLoadFormDataNew
            )
                .then((res) => {
                    if (res.json && res.json?.success === true) {
                        ToastMsg("success", res.json.message);
                        ramzanListApi();
                        setSaveBotton(false);
                    } else {
                        ToastMsg("error", res.json.message);
                    }
                })
                .catch((err) => {
                    setLoader(false);

                    ToastMsg("error", "something went wrong");
                });
    };
    useEffect(() => {
        ramzanListApi();
    }, []);
    //
    const ramzanListApi = () => {
        // setIsLoading(true);

        simpleGetCallWithErrorResponse(ApiConfig.LISTINTRO)
            .then((res) => {
                setLoader(false);

                if (res.json && res.json?.success === true) {
                    console.log("introList", res.json?.data)
                    setMasaiList(res.json?.data);
                    setLsatSabaq(res.json?.data.length);

                    // ToastMsg("success", res.json.message);
                } else {
                    ToastMsg("error", res.json.message);
                }
            })
            .catch((err) => {
                setLoader(false);

                ToastMsg("error", "something went wrong");
            });
    };

    const ramzanDeleteApi = () => {

        DeleteCallWithErrorResponse(
            ApiConfig.DELETEINTRO + `${deleteId}`,


        )
            .then((res) => {
                setLoader(false);

                handleDeleteClose();
                if (res.json && res.json?.success === true) {
                    ToastMsg("success", res.json.message);
                    ramzanListApi();
                } else {
                    ToastMsg("error", res.json.message);
                }
            })
            .catch((err) => {
                setLoader(false);

                ToastMsg("error", "something went wrong");
            });
    };
    //
    const EditListApi = (id, param) => {

        simpleGetCallWithErrorResponse(ApiConfig.VIEWINTRO + `${id}`)
            .then((res) => {
                setLoader(false);

                if (res.json && res.json?.success === true) {
                    let data = res.json?.data;
                    console.log("update", data)
                    setAddNamaz(data)
                    handleAddShow();

                    // ToastMsg("success", res.json.message);
                } else {
                    ToastMsg("error", res.json.message);
                }
            })
            .catch((err) => {
                setLoader(false);

                ToastMsg("error", "something went wrong");
            });
    };
    //view the
    const viewDetailshandle = (id) => {
        simpleGetCallWithErrorResponse(ApiConfig.VIEWINTRO + `${id}`)
            .then((res) => {
                setLoader(false);

                if (res.json && res.json?.success === true) {
                    setViewDetails(res.json.data);
                    console.log(":viewDetails---->", res.json.data)
                    handleViewShow();
                    // ToastMsg("success", res.json.message);
                } else {
                    ToastMsg("error", res.json.message);
                }
            })
            .catch((err) => {
                setLoader(false);

                ToastMsg("error", "something went wrong");
            });
    };

    return (
        <main
            id="cx-main"
            className={sidebar ? "admin-main" : "cx-active admin-main"}
        >
            <div className="heading-btn-main">
                <div className="heading-common">
                    <label htmlFor="">Intro</label>
                </div>
                <div className="add-btn">
                    <button onClick={handleAddShow}>
                        Add Intro
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 24 24"
                        >
                            <path fill="white" d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" />
                        </svg>
                    </button>
                </div>
            </div>
            {/* ======================= START ADD ========================== */}
            <Modal
                show={add}
                // onHide={handleAddClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton onHide={handleAddClose}>
                    {/* <Modal.Title>Add Sabaq</Modal.Title> */}
                    {idEdit === "edit" ? "Update  Intro" : "Add Intro"}

                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <Form.Label>Intro Title</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    value={addNamaz.intro_file_title}
                                    onChange={(e) =>
                                        setAddNamaz({ ...addNamaz, intro_file_title: e.target.value })
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please Enter Title
                                </Form.Control.Feedback>
                            </div>
                            <div className="col-md-6 mb-3">
                                <Form.Label>Intro Desription</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    value={addNamaz.intro_file_desc}
                                    onChange={(e) =>
                                        setAddNamaz({ ...addNamaz, intro_file_desc: e.target.value })
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please Enter Description
                                </Form.Control.Feedback>
                            </div>
                            <div className="col-md-6 mb-3">
                                {idEdit ? (
                                    <>
                                        <div className="row d-flex">
                                            <div className="col-md-12">
                                                <label htmlFor="" className="key">
                                                    Sabaq Audio
                                                </label>
                                            </div>
                                            <div
                                                className={imageWidth === "100%" ? "d-none" : "col-md-6 "}
                                            >
                                                <Form.Label>
                                                    {typeof addNamaz?.intro_file_path === "string"
                                                        ? addNamaz?.intro_file_path.substring(
                                                            addNamaz?.intro_file_path.length - 15,
                                                            addNamaz?.intro_file_path.length
                                                        )
                                                        : ""}
                                                </Form.Label>
                                            </div>
                                            <div className="col-md-12 mb-3 ">
                                                <Form.Control
                                                    type="file"
                                                    // accept="audio/mp3,audio/*;capture=microphone"
                                                    name="intro_file_path"
                                                    onChange={(e) => {
                                                        setAddNamaz({ ...addNamaz, intro_file_path: e.target.files[0] })
                                                    }}
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Sabaq Name
                                                </Form.Control.Feedback>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="col-md-12 mb-3">
                                        <Form.Label>Intro Video</Form.Label>
                                        <Form.Control
                                            required
                                            type="file"
                                            // value={addNamaz?.intro_file_path}
                                            // accept="video/mp4 "
                                            onChange={(e) =>
                                                setAddNamaz({ ...addNamaz, intro_file_path: e.target.files[0] })
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Viedo
                                        </Form.Control.Feedback>


                                    </div>
                                )}

                            </div>
                        </div>
                        <div className="buttons-modal border-top pt-3 text-end ">
                            <button className="me-2" type="submit">
                                {idEdit === "edit" ? "update" : "Save"}
                            </button>
                            <Link
                                to="#"
                                className=" bg-danger"
                                onClick={() => {
                                    setAddNamaz({
                                        sabaqName: "",
                                        sabaqDesc: "",
                                        sabaqDescAudio: "",
                                        sabaqNameAudio: "",

                                        sabaqDescReference: "",

                                        subSabaq: [
                                            {
                                                subSabaqTitle: "",
                                                subSabaqTitleAudio: "",
                                                subSabaqDesc: "",
                                                subSabaqDescAudio: "",
                                                subSabaqDescReference: "",
                                            },
                                        ],
                                    });
                                    handleAddClose();
                                    setIdEdit("");
                                }}
                            >
                                Cancel
                            </Link>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            {/* ======================= END ADD ========================== */}

            {/* ======================= DELETE ========================== */}

            <Modal
                show={deleteModal}
                onHide={handleDeleteClose}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="delete-msg text-center">
                        <label htmlFor="">Are you sure you want to delete this item?</label>
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <div className="buttons-modal    ">
                        <button
                            className="me-2 bg-danger"
                            type="submit"
                            onClick={() => {
                                handleDeleteClose()
                                ramzanDeleteApi()
                            }}
                        >
                            Delete
                        </button>
                        <Link to="#" className="" onClick={handleDeleteClose}>
                            Cancel
                        </Link>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* ======================= END DELETE ========================== */}

            {/* ======================= VIEW ========================== */}
            <Modal
                show={viewModal}
                onHide={handleViewClose}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ramazan Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <div>
                                    <label htmlFor="" className="key">
                                        Intro Video Title
                                    </label>
                                </div>
                                <div>
                                    <label htmlFor="" className="value">
                                        {viewDetails?.intro_file_title}
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <div>
                                    <label htmlFor="" className="key">
                                        Intro Video Description
                                    </label>
                                </div>
                                <div>
                                    <label htmlFor="" className="value">
                                        {viewDetails?.intro_file_desc}
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <div>
                                    <label htmlFor="" className="key">
                                        Intro Video
                                    </label>
                                </div>
                                <div>
                                    <video controls style={{ width: "100%", height: "auto" }}>
                                        <source src={viewDetails?.intro_file_path} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                        </div>
                        <div className="buttons-modal border-top pt-3 text-end ">
                            <Link to="#" className="bg-danger" onClick={handleViewClose}>
                                Cancel
                            </Link>
                        </div>

                    </Form>
                </Modal.Body>
            </Modal>
            {/* ======================= END VIEW ========================== */}
            {loader ? (
                <FormLoader />
            ) : (
                <div className="table-wrapper">
                    <h6>Total Records : {masaiList.length}</h6>
                    <Table className="display" striped bordered hover size="lg">
                        <thead>
                            <tr>
                                <th>Sabaq No.</th>
                                <th style={{ textAlign: "left" }}>intro List</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {masaiList && masaiList.length > 0
                                ? masaiList.slice(startIndex, endIndex).map((ele, index) => {
                                    return (
                                        <tr>
                                            <td>{index + 1 + startIndex}</td>

                                            <td style={{ textAlign: "left" }}>{ele.intro_file_title}</td>
                                            <td className="action">
                                                <Link
                                                    to="#"
                                                    className="icon edit"
                                                    onClick={() => {
                                                        // handleAddShow();
                                                        setIdEdit("edit");
                                                        setImageWidth({
                                                            ...imageWidth,
                                                            sabaqAudio: "117px",
                                                            Uploade: "117px",
                                                            Uploade1: "117px",
                                                            Tarjama: "117px",
                                                            Description: "117px",
                                                            subSabaqTitle: "117px",
                                                        });
                                                        EditListApi(ele.intro_file_id);
                                                        // handleAddShow();

                                                        setIntroId(ele.intro_file_id)
                                                    }}
                                                >
                                                    <img src={ic_edit} alt="" />
                                                </Link>
                                                <Link
                                                    to="#"
                                                    className="icon view"
                                                    onClick={() => viewDetailshandle(ele.intro_file_id)}
                                                >
                                                    <img src={ic_eyes} alt="" />
                                                </Link>
                                                <Link
                                                    to="#"
                                                    className="icon delete"
                                                    onClick={() => {
                                                        handleDeleteShow();
                                                        setDeleteId(ele.intro_file_id);
                                                    }}
                                                >
                                                    <img src={ic_delete} alt="" />
                                                </Link>
                                            </td>
                                        </tr>
                                    );
                                })
                                : ""}
                        </tbody>
                    </Table>
                    {masaiList && masaiList.length > 0 ? (
                        <Pagination
                            changePage={changePage}
                            pageCount={pageCount}
                            currentPage={currentPage}
                        />
                    ) : (
                        <div>
                            <h5 style={{ color: "red", textAlign: "center" }}>
                                {" "}
                                Records Not Found
                            </h5>
                        </div>
                    )}
                </div>
            )}
        </main>
    );
};

export default Intro;
