import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "./../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import Table from "react-bootstrap/Table";

import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $, { data } from "jquery";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import ic_delete from "../assets/images/ic_delete.svg";
import ic_edit from "../assets/images/ic_edit.svg";
import ic_eyes from "../assets/images/ic_eyes.svg";
import { ToastMsg } from "../pages/TostMsg";
import Modal from "react-bootstrap/Modal";
import ApiConfig from "../api/ApiConfig";
import {
  DeleteCallWithErrorResponse,
  multipartPostCallWithErrorResponse,
  multipartPutCallWithErrorResponse,
  PostCallWithAuthTokenWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../api/ApiServices";

import FormLoader from "./FormLoader";
import { ScaleLoader } from "react-spinners";
import { Pagination } from "./Pagination";
const NoraaniQaaidah = () => {
  const { sidebar, adminData } = useContext(AppContext);
  const [loader, setLoader] = useState(true);
  const [sabaqList, setSabaqList] = useState([]);
  const [lasatSabaqId, setLsatSabaq] = useState("");
  const [viewDetails, setViewDetails] = useState({});
  const [idEdit, setIdEdit] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [testEdit, setTestEdit] = useState("");
  const [viewSabaqList, setViewSabaqList] = useState([]);
  const [filterEdit, setFilterEdit] = useState({});
  const [saveBotton, setSaveBotton] = useState(false);
  const [imageWidth, setImageWidth] = useState({
    sabaq: "100%",
    des: "100%",
    Title: "100%",
    subdesc: "100%",
    mashq: "100%",
  });

  const [addSabaq, setAddSabaq] = useState({
    sabaqName: "",
    sabaqAudio: "",
    sabaqDesc: "",
    sabaqDescAudio: "",
    subSabaqs: [
      {
        subSabaqTitle: "",
        titleAudioPath: "",
        subSabaqDesc: "",
        descAudioPath: "",
        mashqs: [
          {
            mashq: "",
            mashqAudioPath: "",
          },
        ],
      },
    ],
  });

  const [AddNewGroup, setAddNewGroup] = useState(0);
  const [AddsabaqMashq, setAddsabaqMashq] = useState(0);
  const [add, setAdd] = useState(false);
  const handleAddClose = (test) => {
    setAdd(false);
    setIdEdit(test);
    setAddSabaq({
      sabaqName: "",
      sabaqAudio: "",
      sabaqDesc: "",
      sabaqDescAudio: "",
      subSabaqs: [
        {
          subSabaqTitle: "",
          titleAudioPath: "",
          subSabaqDesc: "",
          descAudioPath: "",
          mashqs: [
            {
              mashq: "",
              mashqAudioPath: "",
            },
          ],
        },
      ],
    });
  };
  const handleAddShow = (set) => {
    if (set === "add") {
      setIdEdit("");
      setAddSabaq({
        sabaqName: "",
        sabaqAudio: "",
        sabaqDesc: "",
        sabaqDescAudio: "",
        subSabaqs: [
          {
            subSabaqTitle: "",
            titleAudioPath: "",
            subSabaqDesc: "",
            descAudioPath: "",
            mashqs: [
              {
                mashq: "",
                mashqAudioPath: "",
              },
            ],
          },
        ],
      });
    }
    setAdd(true);
  };

  const [deleteModal, setDelete] = useState(false);
  const handleDeleteClose = () => {
    setDelete(false);
  };
  const handleDeleteShow = () => setDelete(true);

  const [viewModal, setView] = useState(false);
  const handleViewClose = () => {
    setView(false);
  };
  const handleViewShow = () => setView(true);
  const [isdeleteList, setIsDeleteList] = useState(false);
  const [validated, setValidated] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const startIndex = currentPage * 15;
  const endIndex = startIndex + 15;
  const changePage = ({ selected }) => {
    setCurrentPage(selected);
  };
  const pageCount = Math.ceil(sabaqList?.length / 15);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      addSabaqApi();
    }

    setValidated(true);
  };

  const addSabaqApi = () => {
    setSaveBotton(true);
    handleAddClose();
    setLoader(true);
    const body = {
      categoryId: 2,
      userId: Number(adminData.user_id),
      sabaqName: addSabaq.sabaqName,
      sabaqDesc: addSabaq.sabaqDesc,
      sabaqNo: lasatSabaqId + 1,
      sabaqId: addSabaq.SabaqId,
      // sabaqId:lsatSabaqId
    };
    let payLoadFormData = new FormData();
    payLoadFormData.append("categoryId", body.categoryId);
    payLoadFormData.append("userId", body.userId);
    payLoadFormData.append("sabaqName", body.sabaqName ? body.sabaqName : "");
    // payLoadFormData.append("sabaqNo", body.sabaqNo);
    payLoadFormData.append("sabaqDesc", body.sabaqDesc ? body.sabaqDesc : "");
    idEdit === "edit"
      ? payLoadFormData.append("sabaqId", body.sabaqId ? body.sabaqId : "")
      : payLoadFormData.append("sabaqNo", body.sabaqNo ? body.sabaqNo : "");
    // payLoadFormData.append("categoryName", body.categoryName);
    addSabaq?.subSabaqs?.length > 0 &&
      addSabaq.subSabaqs.map((ele) =>
        payLoadFormData.append(
          "subSabaqTitleAudio",
          ele.titleAudioPath ? ele.titleAudioPath : ""
        )
      );
    //
    addSabaq?.subSabaqs?.length > 0 &&
      addSabaq.subSabaqs.map((ele) =>
        payLoadFormData.append(
          "subSabaqDescAudio",
          ele.descAudioPath ? ele.descAudioPath : ""
        )
      );
    addSabaq?.subSabaqs?.length > 0 &&
      addSabaq.subSabaqs.map((ele, index) =>
        ele.mashqs.map((audiodata) =>
          payLoadFormData.append(
            "mashqAudio",
            audiodata.mashqAudioPath ? audiodata.mashqAudioPath : ""
          )
        )
      );

    let newSubaqAudio = [];
    newSubaqAudio.push(addSabaq.sabaqAudio);

    let newSubaqDesceAudio = [];
    newSubaqDesceAudio.push(addSabaq.sabaqDescAudio);
    //descreption mashqAudioPath
    newSubaqDesceAudio.map((ele) =>
      payLoadFormData.append("sabaqDescAudio", ele ? ele : "")
    );

    //subaq mashqAudioPath
    newSubaqAudio.map((ele) =>
      payLoadFormData.append("sabaqNameAudio", ele ? ele : "")
    );
    // console.log(JSON.parse(payLoadFormData.getAll("sabaqDescAudio")),"testing data")
    //new

    let subSabaq =
      addSabaq?.subSabaqs?.length > 0 &&
      addSabaq.subSabaqs.map((ele, index) => {
        return {
          subSabaqTitle: ele.subSabaqTitle,
          subSabaqTitleAudio:
            ele.titleAudioPath == "" ? "no file" : "file name",
          subSabaqDescAudio: ele.descAudioPath == "" ? "no file" : "file name",
          subSabaqDesc: ele.subSabaqDesc,
          subSabaqNo: index + 1,
          mashqs: ele.mashqs.map((elesub) => {
            return {
              mashq: elesub.mashq,
              mashqAudio: elesub.mashqAudioPath == "" ? "no file" : "file name",
            };
          }),
        };
      });

    let subSabaqEDIT =
      addSabaq?.subSabaqs?.length > 0 &&
      addSabaq.subSabaqs.map((ele, index) => {
        console.log(typeof ele.titleAudioPath, "testuing ");
        return {
          subSabaqTitle: ele.subSabaqTitle ? ele.subSabaqTitle : "",
          subSabaqDesc: ele.subSabaqDesc ? ele.subSabaqDesc : "",
          subSabaqTitleAudio:
            typeof ele.titleAudioPath == "object" ? "updated" : "not updated",
          subSabaqDescAudio:
            typeof ele.descAudioPath == "object" ? "updated" : "not updated",
          // subSabaqNo: ele.subSabaqNo,
          subSabaqNo: index + 1,

          subSabaqId: ele.subSabaqId,
          mashqs: ele.mashqs.map((elesub) => {
            return {
              mashq: elesub.mashq ? elesub.mashq : "",
              mashqId: elesub.mashqId,
              mashqAudio:
                typeof elesub.mashqAudioPath == "object"
                  ? "updated"
                  : "not updated",
            };
          }),
        };
      });
    idEdit === "edit"
      ? payLoadFormData.append("subSabaq", JSON.stringify(subSabaqEDIT))
      : payLoadFormData.append("subSabaq", JSON.stringify(subSabaq));
    // console.log(subSabaqEDIT,"new file")

    // setIsLoading(true);
    idEdit === "edit"
      ? multipartPutCallWithErrorResponse(
          ApiConfig.SUBAQUPDATENORANI,
          payLoadFormData
        )
          .then((res) => {
            if (res.json && res.json?.success === true) {
              ToastMsg("success", res.json.message);

              SabaqListApi();
              setIdEdit("");
              setSaveBotton(false);
            } else {
              ToastMsg("error", res.json.message);
            }
          })
          .catch((err) => {
            setLoader(false);

            ToastMsg("error", "something went wrong");
          })
      : multipartPostCallWithErrorResponse(ApiConfig.SUBAQADD, payLoadFormData)
          .then((res) => {
            setLoader(false);

            if (res.json && res.json?.success === true) {
              ToastMsg("success", res.json.message);

              SabaqListApi();
              setIdEdit("");
              setSaveBotton(false);
            } else {
              ToastMsg("error", res.json.message);
            }
          })
          .catch((err) => {
            setLoader(false);

            ToastMsg("error", "something went wrong");
          });
  };

  //subaqlist api
  useEffect(() => {
    SabaqListApi();
  }, []);

  const SabaqListApi = () => {
    simpleGetCallWithErrorResponse(ApiConfig.SUBAQLIST + `?categoryId=${2}`)
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          setSabaqList(res.json?.data.allSabaq);
          setLsatSabaq(res.json?.data.allSabaq.length);
          // pagination();
          // setLsatSabaqMash(
          //   res.json?.data.allSabaq[res.json.data?.allSabaq.length - 1]
          // );
          setAddSabaq([
            {
              sabaqName: "",
              sabaqAudio: "",
              sabaqDesc: "",
              sabaqDescAudio: "",
              subSabaqs: [
                {
                  subSabaqTitle: "",
                  titleAudioPath: "",
                  subSabaqDesc: "",
                  descAudioPath: "",
                  mashqs: [
                    {
                      mashq: "",
                      mashqAudioPath: "",
                    },
                  ],
                },
              ],
            },
          ]);
        } else {
          ToastMsg("error", res.json?.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };
  // useEffect(() => {
  //   sabaqList.length && pagination();
  // }, [sabaqList]);

  // useEffect(())
  const SabaqLisDeleteApi = () => {
    handleDeleteClose();
    const body = {
      categoryId: 2,
      sabaqId: deleteId,
    };
    // setIsLoading(true);
    DeleteCallWithErrorResponse(
      ApiConfig.DELETEAPI,

      JSON.stringify({
        ...body,
      })
    )
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          ToastMsg("success", res.json.message);

          SabaqListApi();

          // setIsDeleteList(true);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  const viewDetailshandle = (id) => {
    simpleGetCallWithErrorResponse(ApiConfig.EDIT + `?sabaqId=${id}`)
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          setViewDetails(res.json.data);
          handleViewShow();
          // ToastMsg("success", res.json.message);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  const EditListApi = (id) => {
    simpleGetCallWithErrorResponse(ApiConfig.EDIT + `?sabaqId=${id}`)
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          setAddSabaq(res.json.data);

          handleAddShow();
          // ToastMsg("success", res.json.message);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  // <ScaleLoader
  //         color={"#004280"}
  //         loading={loader}
  //         style={{
  //           display: "block",
  //           textAlign: "center",
  //           margin: "20%",
  //           borderColor: "#b76b29",
  //         }}
  //         // css={override}
  //         size={300}
  //       />

  return (
    <>
      <main
        id="cx-main"
        className={sidebar ? "admin-main" : "cx-active admin-main"}
      >
        <div className="heading-btn-main">
          <div className="heading-common">
            <label htmlFor="">Noraani Qaaidah</label>
          </div>
          <div className="add-btn">
            <button onClick={() => handleAddShow("add")}>
              Add Sabaq
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path fill="white" d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" />
              </svg>
            </button>
          </div>
        </div>
        {/* ======================= START ADD ========================== */}
        <Modal
          show={add}
          onHide={() => handleAddClose()}
          size="lg"
          aria-labelledby="contained-modal-subSabaqTitle-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {idEdit === "edit"
                ? "Update Noraani Qaaidah"
                : "Add Noraani Qaaidah"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <Form.Label>Sabaq Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="sabaqName"
                    value={addSabaq?.sabaqName}
                    onChange={(e) =>
                      setAddSabaq({ ...addSabaq, sabaqName: e.target.value })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Sabaq Name
                  </Form.Control.Feedback>
                </div>

                {/* ADDD */}
                {idEdit ? (
                  <>
                    <div className="row d-flex">
                      <div className="col-md-12">
                        <label htmlFor="" className="key">
                          Sabaq Audio
                        </label>
                      </div>
                      <div
                        className={
                          imageWidth === "100%" ? "d-none" : "col-md-6 "
                        }
                      >
                        <Form.Label>
                          {typeof addSabaq?.sabaqAudio === "string"
                            ? addSabaq?.sabaqAudio.substring(
                                addSabaq?.sabaqAudio.length - 15,
                                addSabaq?.sabaqAudio.length
                              )
                            : ""}
                        </Form.Label>
                      </div>
                      <div className="col-md-12 mb-3 ">
                        <Form.Control
                          type="file"
                          accept="audio/mp3,audio/*;capture=microphone"
                          name="sabaqAudio"
                          style={{ width: imageWidth.sabaq }}
                          //  value={addSabaq?.sabaqAudio}
                          onChange={(e) => {
                            setImageWidth({ ...imageWidth, sabaq: "100%" });
                            setAddSabaq({
                              ...addSabaq,
                              sabaqAudio: e.target.files[0],
                            });
                          }}
                        />

                        <Form.Control.Feedback type="invalid">
                          Please Enter Sabaq Name
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="col-md-12 mb-3">
                    <Form.Label>Sabaq Audio</Form.Label>
                    <Form.Control
                      // required
                      type="file"
                      accept="audio/mp3,audio/*;capture=microphone"
                      name="sabaqAudio"
                      src={addSabaq?.sabaqAudio}
                      //  / value={addSabaq?.sabaqAudio}
                      onChange={(e) => {
                        setAddSabaq({
                          ...addSabaq,
                          sabaqAudio: e.target.files[0],
                        });
                      }}
                    />

                    <Form.Control.Feedback type="invalid">
                      Please Enter Sabaq Name
                    </Form.Control.Feedback>
                  </div>
                )}

                <div className="col-md-12 mb-3">
                  <Form.Label>Sabaq Description</Form.Label>
                  <Form.Control
                    // required
                    as="textarea"
                    rows={3}
                    name="sabaqDesc"
                    value={addSabaq?.sabaqDesc}
                    onChange={(e) =>
                      setAddSabaq({ ...addSabaq, sabaqDesc: e.target.value })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Tarjama.
                  </Form.Control.Feedback>
                </div>
                {/* <div className="col-md-12 mb-3">
                  <Form.Label>Description Audio</Form.Label>
                  <Form.Control
                    type="file"
                    name="sabaqDescAudio"
                    // value={addSabaq?.sabaqDescAudio}
                    onChange={(e) =>
                      setAddSabaq({
                        ...addSabaq,
                        sabaqDescAudio: e.target.files[0],
                      })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Sabaq Name
                  </Form.Control.Feedback>
                </div> */}
                {idEdit ? (
                  <>
                    <div className="row d-flex">
                      <div className="col-md-12">
                        <label htmlFor="" className="key">
                          Sabaq Description Audio
                        </label>
                      </div>
                      <div
                        className={
                          imageWidth === "100%" ? "invisible" : "col-md-6 "
                        }
                      >
                        <Form.Label>
                          {typeof addSabaq?.sabaqDescAudio === "string"
                            ? addSabaq?.sabaqDescAudio.substring(
                                addSabaq?.sabaqDescAudio.length - 15,
                                addSabaq?.sabaqDescAudio.length
                              )
                            : ""}
                        </Form.Label>
                      </div>
                      <div className="col-md-12 mb-3">
                        <Form.Control
                          type="file"
                          accept="audio/mp3,audio/*;capture=microphone"
                          name="sabaqAudio"
                          style={{ width: imageWidth.des }}
                          //  value={addSabaq?.sabaqAudio}
                          onChange={(e) => {
                            setImageWidth({ ...imageWidth, des: "100%" });
                            setAddSabaq({
                              ...addSabaq,
                              sabaqDescAudio: e.target.files[0],
                            });
                          }}
                        />

                        <Form.Control.Feedback type="invalid">
                          Please Enter Sabaq Name
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="col-md-12 mb-3">
                    <Form.Label>Sabaq Description Audio </Form.Label>
                    <Form.Control
                      // required
                      type="file"
                      accept="audio/mp3,audio/*;capture=microphone"
                      name="sabaqDescAudio"
                      src={addSabaq?.sabaqAudio}
                      //  / value={addSabaq?.sabaqAudio}
                      onChange={(e) => {
                        setAddSabaq({
                          ...addSabaq,
                          sabaqDescAudio: e.target.files[0],
                        });
                      }}
                    />

                    <Form.Control.Feedback type="invalid">
                      Please Enter Sabaq Name
                    </Form.Control.Feedback>
                  </div>
                )}
                {addSabaq?.subSabaqs &&
                  addSabaq?.subSabaqs.map((item, index) => {
                    return (
                      <div
                        className="col-md-12 sub-group-main"
                        key={"noorani" + index}
                      >
                        <div className="row align-items-end">
                          <h6 className="fw-bold">
                            Sub Sabaq No. <span>{index + 1}</span>
                          </h6>
                          <div className="col-md-12 mb-3">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                              // required
                              type="text"
                              value={item?.subSabaqTitle}
                              onChange={(e) => {
                                setAddSabaq({
                                  ...addSabaq,
                                  subSabaqs: addSabaq.subSabaqs.map(
                                    (item, innerIndex) => {
                                      return index === innerIndex
                                        ? {
                                            ...item,
                                            subSabaqTitle: e.target.value,
                                          }
                                        : item;
                                    }
                                  ),
                                });
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Choose Audio File.
                            </Form.Control.Feedback>
                          </div>
                          {/* <div className="col-md-12 mb-3">
                            <Form.Label>Title Audio</Form.Label>
                            <Form.Control
                              type="file"
                              // value={item?.titleAudioPath}
                              onChange={(e) => {
                                setAddSabaq({
                                  ...addSabaq,
                                  subSabaqs: addSabaq.subSabaqs.map(
                                    (item, innerIndex) => {
                                      return index === innerIndex
                                        ? {
                                            ...item,
                                            titleAudioPath: e.target.files[0],
                                          }
                                        : item;
                                    }
                                  ),
                                });
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Choose Audio File.
                            </Form.Control.Feedback>
                          </div> */}

                          {idEdit ? (
                            <>
                              <div className="row d-flex">
                                <div className="col-md-12">
                                  <label htmlFor="" className="key">
                                    Title Audio
                                  </label>
                                </div>
                                <div
                                  className={
                                    imageWidth === "100%"
                                      ? "invisible"
                                      : "col-md-6 "
                                  }
                                >
                                  <Form.Label>
                                    {typeof item?.titleAudioPath === "string"
                                      ? item?.titleAudioPath.substring(
                                          item?.titleAudioPath.length - 15,
                                          item?.titleAudioPath.length
                                        )
                                      : ""}
                                  </Form.Label>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <Form.Control
                                    type="file"
                                    accept="audio/mp3,audio/*;capture=microphone"
                                    name="sabaqAudio"
                                    style={{ width: imageWidth.Title }}
                                    //  value={addSabaq?.sabaqAudio}
                                    onChange={(e) => {
                                      setImageWidth({
                                        ...imageWidth,
                                        Title: "100%",
                                      });
                                      setAddSabaq({
                                        ...addSabaq,
                                        subSabaqs: addSabaq.subSabaqs.map(
                                          (item, innerIndex) => {
                                            return index === innerIndex
                                              ? {
                                                  ...item,
                                                  titleAudioPath:
                                                    e.target.files[0],
                                                }
                                              : item;
                                          }
                                        ),
                                      });
                                    }}
                                  />

                                  <Form.Control.Feedback type="invalid">
                                    Please Enter Sabaq Name
                                  </Form.Control.Feedback>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="col-md-12 mb-3">
                              <Form.Label>Title Audio </Form.Label>
                              <Form.Control
                                type="file"
                                accept="audio/mp3,audio/*;capture=microphone"
                                onChange={(e) => {
                                  setAddSabaq({
                                    ...addSabaq,
                                    subSabaqs: addSabaq.subSabaqs.map(
                                      (item, innerIndex) => {
                                        return index === innerIndex
                                          ? {
                                              ...item,
                                              titleAudioPath: e.target.files[0],
                                            }
                                          : item;
                                      }
                                    ),
                                  });
                                }}
                              />

                              <Form.Control.Feedback type="invalid">
                                Please Enter Sabaq Name
                              </Form.Control.Feedback>
                            </div>
                          )}
                          <div className="col-md-12 mb-3">
                            <Form.Label>Sub Sabaq Description</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={item?.subSabaqDesc}
                              onChange={(e) => {
                                setAddSabaq({
                                  ...addSabaq,
                                  subSabaqs: addSabaq.subSabaqs.map(
                                    (item, innerIndex) => {
                                      return index === innerIndex
                                        ? {
                                            ...item,
                                            subSabaqDesc: e.target.value,
                                          }
                                        : item;
                                    }
                                  ),
                                });
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter Tarjama.
                            </Form.Control.Feedback>
                          </div>
                          {/* <div className="col-md-12 mb-3">
                            <Form.Label>Description Audio</Form.Label>
                            <Form.Control
                              type="file"
                              // value={item?.descAudioPath}
                              onChange={(e) => {
                                setAddSabaq({
                                  ...addSabaq,
                                  subSabaqs: addSabaq.subSabaqs.map(
                                    (item, innerIndex) => {
                                      return index === innerIndex
                                        ? {
                                            ...item,
                                            descAudioPath: e.target.files[0],
                                          }
                                        : item;
                                    }
                                  ),
                                });
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter Sabaq Name
                            </Form.Control.Feedback>
                          </div> */}

                          {idEdit ? (
                            <>
                              <div className="row d-flex">
                                <div className="col-md-12">
                                  <label htmlFor="" className="key">
                                    Sub Sabaq Description Audio
                                  </label>
                                </div>
                                <div
                                  className={
                                    imageWidth === "100%"
                                      ? "invisible"
                                      : "col-md-6 "
                                  }
                                >
                                  <Form.Label>
                                    {typeof item?.descAudioPath === "string"
                                      ? item?.descAudioPath.substring(
                                          item?.descAudioPath.length - 15,
                                          item?.descAudioPath.length
                                        )
                                      : ""}
                                  </Form.Label>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <Form.Control
                                    type="file"
                                    accept="audio/mp3,audio/*;capture=microphone"
                                    name="sabaqAudio"
                                    style={{ width: imageWidth.subdesc }}
                                    //  value={addSabaq?.sabaqAudio}
                                    onChange={(e) => {
                                      setImageWidth({
                                        ...imageWidth,
                                        subdesc: "100%",
                                      });
                                      setAddSabaq({
                                        ...addSabaq,
                                        subSabaqs: addSabaq.subSabaqs.map(
                                          (item, innerIndex) => {
                                            return index === innerIndex
                                              ? {
                                                  ...item,
                                                  descAudioPath:
                                                    e.target.files[0],
                                                }
                                              : item;
                                          }
                                        ),
                                      });
                                    }}
                                  />

                                  <Form.Control.Feedback type="invalid">
                                    Please Enter Sabaq Name
                                  </Form.Control.Feedback>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="col-md-12 mb-3">
                              <Form.Label>
                                {" "}
                                Sub Sabaq Description Audio{" "}
                              </Form.Label>
                              <Form.Control
                                type="file"
                                accept="audio/mp3,audio/*;capture=microphone"
                                onChange={(e) => {
                                  setAddSabaq({
                                    ...addSabaq,
                                    subSabaqs: addSabaq.subSabaqs.map(
                                      (item, innerIndex) => {
                                        return index === innerIndex
                                          ? {
                                              ...item,
                                              descAudioPath: e.target.files[0],
                                            }
                                          : item;
                                      }
                                    ),
                                  });
                                }}
                              />

                              <Form.Control.Feedback type="invalid">
                                Please Enter Sabaq Name
                              </Form.Control.Feedback>
                            </div>
                          )}
                          <div className="d-flex justify-content-between align-items-center mb-3 ">
                            <div className="">
                              <h6 className="fw-bold ">Sabaq Mashq </h6>
                            </div>

                            <div className="plus-btn text-end">
                              <Link
                                to="#"
                                className={
                                  // idEdit
                                  // ? "pe-none btn  btn-secondary"
                                  // :
                                  "btn btn-success"
                                }
                                onClick={() => {
                                  setAddSabaq({
                                    ...addSabaq,
                                    subSabaqs: addSabaq.subSabaqs.map(
                                      (item, i) => {
                                        if (i === index) {
                                          let mashqs = [...item.mashqs];
                                          mashqs.push({
                                            mashq: "",
                                            mashqAudioPath: "",
                                          });
                                          return {
                                            ...item,
                                            mashqs,
                                          };
                                        } else return item;
                                      }
                                    ),
                                  });
                                }}
                              >
                                Add+
                              </Link>
                            </div>
                          </div>

                          <hr />

                          {item.mashqs &&
                            item.mashqs.map((innerItem, innerIndex) => {
                              return (
                                <div
                                  className="row align-items-end justify-mashq-between"
                                  key={"masq" + index}
                                >
                                  <div className="col-md-5 mb-3">
                                    <Form.Label> Mashq Arbic</Form.Label>
                                    <Form.Control
                                      type="text"
                                      // value={innerItem?.mashq}
                                      // value={innerItem?.mashq}
                                      value={innerItem?.mashq}
                                      onChange={(e) => {
                                        setAddSabaq({
                                          ...addSabaq,
                                          subSabaqs: addSabaq.subSabaqs.map(
                                            (innerItem, innerIndex1) => {
                                              return index === innerIndex1
                                                ? {
                                                    ...innerItem,
                                                    mashqs:
                                                      innerItem.mashqs.map(
                                                        (
                                                          innerItem2,
                                                          innerIndex2
                                                        ) => {
                                                          return innerIndex ===
                                                            innerIndex2
                                                            ? {
                                                                ...innerItem2,
                                                                mashq:
                                                                  e.target
                                                                    .value,
                                                              }
                                                            : innerItem2;
                                                        }
                                                      ),
                                                  }
                                                : innerItem;
                                            }
                                          ),
                                        });
                                      }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Please Choose Audio File.
                                    </Form.Control.Feedback>
                                  </div>

                                  {/* <div className="col-md-5 mb-3">
                                    <Form.Label>Audio</Form.Label>
                                    <Form.Control
                                      type="file"
                                      // value={innerItem?.mashqAudioPath}
                                      onChange={(e) => {
                                        setAddSabaq({
                                          ...addSabaq,
                                          subSabaqs: addSabaq.subSabaqs.map(
                                            (innerItem, innerIndex1) => {
                                              return index === innerIndex1
                                                ? {
                                                    ...innerItem,
                                                    mashqs:
                                                      innerItem.mashqs.map(
                                                        (
                                                          innerItem2,
                                                          innerIndex2
                                                        ) => {
                                                          return innerIndex ===
                                                            innerIndex2
                                                            ? {
                                                                ...innerItem2,
                                                                mashqAudioPath:
                                                                  e.target
                                                                    .files[0],
                                                              }
                                                            : innerItem2;
                                                        }
                                                      ),
                                                  }
                                                : innerItem;
                                            }
                                          ),
                                        });
                                      }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Please Choose Audio File.
                                    </Form.Control.Feedback>
                                  </div> */}

                                  {idEdit ? (
                                    <>
                                      <div className="row d-flex">
                                        <div className="col-md-12">
                                          <label htmlFor="" className="key">
                                            Mashq Audio
                                          </label>
                                        </div>
                                        <div
                                          className={
                                            imageWidth === "100%"
                                              ? "invisible"
                                              : "col-md-6 "
                                          }
                                        >
                                          <Form.Label>
                                            {typeof innerItem?.mashqAudioPath ===
                                            "string"
                                              ? innerItem?.mashqAudioPath.substring(
                                                  innerItem?.mashqAudioPath
                                                    .length - 15,
                                                  innerItem?.mashqAudioPath
                                                    .length
                                                )
                                              : ""}
                                          </Form.Label>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                          <Form.Control
                                            type="file"
                                            accept="audio/mp3,audio/*;capture=microphone"
                                            name="sabaqAudio"
                                            style={{ width: imageWidth.mashq }}
                                            //  value={addSabaq?.sabaqAudio}
                                            onChange={(e) => {
                                              setImageWidth({
                                                ...imageWidth,
                                                mashq: "100%",
                                              });
                                              setAddSabaq({
                                                ...addSabaq,
                                                subSabaqs:
                                                  addSabaq.subSabaqs.map(
                                                    (
                                                      innerItem,
                                                      innerIndex1
                                                    ) => {
                                                      return index ===
                                                        innerIndex1
                                                        ? {
                                                            ...innerItem,
                                                            mashqs:
                                                              innerItem.mashqs.map(
                                                                (
                                                                  innerItem2,
                                                                  innerIndex2
                                                                ) => {
                                                                  return innerIndex ===
                                                                    innerIndex2
                                                                    ? {
                                                                        ...innerItem2,
                                                                        mashqAudioPath:
                                                                          e
                                                                            .target
                                                                            .files[0],
                                                                      }
                                                                    : innerItem2;
                                                                }
                                                              ),
                                                          }
                                                        : innerItem;
                                                    }
                                                  ),
                                              });
                                            }}
                                          />

                                          <Form.Control.Feedback type="invalid">
                                            Please Enter Sabaq Name
                                          </Form.Control.Feedback>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="col-md-12 mb-3">
                                      <Form.Label> Mashq Audio</Form.Label>
                                      <Form.Control
                                        type="file"
                                        accept="audio/mp3,audio/*;capture=microphone"
                                        onChange={(e) => {
                                          setAddSabaq({
                                            ...addSabaq,
                                            subSabaqs: addSabaq.subSabaqs.map(
                                              (innerItem, innerIndex1) => {
                                                return index === innerIndex1
                                                  ? {
                                                      ...innerItem,
                                                      mashqs:
                                                        innerItem.mashqs.map(
                                                          (
                                                            innerItem2,
                                                            innerIndex2
                                                          ) => {
                                                            return innerIndex ===
                                                              innerIndex2
                                                              ? {
                                                                  ...innerItem2,
                                                                  mashqAudioPath:
                                                                    e.target
                                                                      .files[0],
                                                                }
                                                              : innerItem2;
                                                          }
                                                        ),
                                                    }
                                                  : innerItem;
                                              }
                                            ),
                                          });
                                        }}
                                      />

                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Sabaq Name
                                      </Form.Control.Feedback>
                                    </div>
                                  )}

                                  <div className="col-2 mb-3 minus-btn">
                                    <Link
                                      to="#"
                                      className={
                                        item.mashqs.length > 1
                                          ? "btn btn-danger"
                                          : "btn btn-secondary invisible"
                                      }
                                      // onClick={() =>
                                      //   setAddsabaqMashq(AddsabaqMashq - 1)
                                      // }
                                      onClick={() => {
                                        if (item.mashqs.length > 1) {
                                          item.mashqs.splice(innerIndex, 1);

                                          const copySubDeatails = [
                                            ...item.mashqs,
                                          ];
                                        }

                                        // const copySubDeatails = [...addSabaq.subSabaqs];
                                        // copySubDeatails.sli
                                      }}
                                      //                                       onClick={() => {
                                      // let mashqList = [...item.mashqs]
                                      // mashqList.splice(innerIndex, 1)

                                      //                                       }}

                                      //   onClick={() => {
                                      // if (addSabaq.subSabaqs.length > 1) {
                                      //   addSabaq.subSabaqs.splice(index, 1);
                                      //   const copySubDeatails = [...addSabaq.subSabaqs];
                                      //   copySubDeatails.splice(index, 1);
                                      //   setAddSabaq({
                                      //     ...addSabaq,
                                      //     subSabaqs: copySubDeatails,
                                      //   });
                                      // }

                                      //
                                    >
                                      -
                                    </Link>
                                  </div>
                                </div>
                              );
                            })}

                          <hr />
                        </div>
                        <div className="cmb-3 minus-btn text-end">
                          <Link
                            to="#"
                            className={
                              addSabaq.subSabaqs.length > 1
                                ? "btn btn-danger"
                                : "btn btn-secondary invisible"
                            }
                            // onClick={() => setAddNewGroup(AddNewGroup - 1)}
                            onClick={() => {
                              if (addSabaq.subSabaqs.length > 1) {
                                // addSabaq.subSabaqs.splice(index, 1);
                                const copySubDeatails = [...addSabaq.subSabaqs];
                                copySubDeatails.splice(index, 1);
                                setAddSabaq({
                                  ...addSabaq,
                                  subSabaqs: copySubDeatails,
                                });
                              }

                              // const copySubDeatails = [...addSabaq.subSabaqs];
                              // copySubDeatails.sli
                            }}
                          >
                            Remove
                          </Link>
                        </div>
                      </div>
                    );
                  })}

                <Link
                  to="#"
                  className={
                    // idEdit
                    // ? "add-newSabaq-btn invisible btn btn-secondary pe-none"
                    // :
                    "add-newSabaq-btn btn btn-primary"
                  }
                  // onClick={() => setAddNewGroup(AddNewGroup + 1)}
                  onClick={() => {
                    let sabak = [
                      ...addSabaq.subSabaqs,

                      {
                        subSabaqTitle: "",
                        titleAudioPath: "",
                        subSabaqDesc: "",
                        descAudioPath: "",
                        mashqs: [
                          {
                            mashq: "",
                            mashqAudioPath: "",
                          },
                        ],
                      },
                    ];
                    setAddSabaq({
                      ...addSabaq,
                      subSabaqs: sabak,
                    });
                  }}
                >
                  + Add Sub Sabaq Details
                </Link>
              </div>
              <div className="buttons-modal border-top pt-3 text-end ">
                <button className="me-2" type="submit">
                  {idEdit === "edit" ? "update" : "Save"}
                </button>
                <Link
                  to="#"
                  className=" bg-danger"
                  onClick={() => {
                    setAddSabaq({
                      sabaqName: "",
                      sabaqAudio: "",
                      sabaqDesc: "",
                      sabaqDescAudio: "",
                      subSabaqs: [
                        {
                          subSabaqTitle: "",
                          titleAudioPath: "",
                          subSabaqDesc: "",
                          descAudioPath: "",
                          mashqs: [
                            {
                              mashq: "",
                              mashqAudioPath: "",
                            },
                          ],
                        },
                      ],
                    });
                    // setTestEdit(addSabaq)
                    handleAddClose();
                    setIdEdit("");
                  }}
                >
                  Cancel
                </Link>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        {/* ======================= END ADD ========================== */}

        {/* ======================= DELETE ========================== */}

        <Modal
          show={deleteModal}
          onHide={handleDeleteClose}
          size="sm"
          aria-labelledby="contained-modal-subSabaqTitle-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="delete-msg text-center">
              <label htmlFor="">
                Are you sure you want to delete this item?
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-mashq-center">
            <div className="buttons-modal    ">
              <button
                className="me-2 bg-danger"
                onClick={() => {
                  handleDeleteClose();
                  SabaqLisDeleteApi();
                }}
              >
                Delete
              </button>
              <Link to="#" className="" onClick={handleDeleteClose}>
                Cancel
              </Link>
            </div>
          </Modal.Footer>
        </Modal>

        {/* ======================= END DELETE ========================== */}

        {/* ======================= VIEW ========================== */}

        <Modal
          show={viewModal}
          onHide={handleViewClose}
          size="xl"
          aria-labelledby="contained-modal-subSabaqTitle-vcenter"
          centered
        >
          ;
          <Modal.Header closeButton>
            <Modal.Title>Noraani Qaaidah Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <div>
                    <label htmlFor="" className="key">
                      Sabaq No.
                    </label>
                  </div>
                  <div>
                    <label htmlFor="" className="value">
                      {viewDetails?.SabaqId}
                    </label>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div>
                    <label htmlFor="" className="key">
                      Sabaq Name
                    </label>
                  </div>
                  <div>
                    <label htmlFor="" className="value">
                      {viewDetails?.sabaqName}
                    </label>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="mb-2">
                    <label htmlFor="" className="key">
                      Sabaq Audio
                    </label>
                  </div>
                  <div>
                    <audio controls style={{ width: "100%", height: "38px" }}>
                      <source src={viewDetails?.sabaqAudio} type="audio/ogg" />
                      <source src={viewDetails?.sabaqAudio} type="audio/mpeg" />
                      Your browser does not support the mashqAudioPath element.
                    </audio>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div>
                    <label htmlFor="" className="key">
                      Sabaq Description
                    </label>
                  </div>
                  <div>
                    <label htmlFor="" className="value">
                      {viewDetails?.sabaqDesc}
                    </label>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="mb-2">
                    <label htmlFor="" className="key">
                      Sabaq Description Audio
                    </label>
                  </div>
                  <div>
                    <audio controls style={{ width: "100%", height: "38px" }}>
                      <source
                        src={viewDetails?.sabaqDescAudio}
                        type="audio/ogg"
                      />
                      <source
                        src={viewDetails?.sabaqDescAudio}
                        type="audio/mpeg"
                      />
                      Your browser does not support the mashqAudioPath element.
                    </audio>
                  </div>
                </div>
                {viewDetails.subSabaqs && viewDetails.subSabaqs.length > 0
                  ? viewDetails?.subSabaqs.map((element, index) => {
                      return (
                        <div
                          className="sub-group-main row mb-3"
                          key={"testkey" + index}
                        >
                          <h6 className="fw-bold mb-4">
                            Sub Sabaq No {index + 1}
                          </h6>
                          <div className="col-md-6 mb-3">
                            <div>
                              <label htmlFor="" className="key">
                                Title
                              </label>
                            </div>
                            <div>
                              <label htmlFor="" className="value">
                                {element?.subSabaqTitle}
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6 mb-3">
                            <div>
                              <label htmlFor="" className="key">
                                Title Audio
                              </label>
                            </div>
                            <div>
                              <audio
                                controls
                                style={{ width: "100%", height: "38px" }}
                              >
                                <source
                                  src={element?.titleAudioPath}
                                  type="audio/ogg"
                                />
                                <source
                                  src={element?.titleAudioPath}
                                  type="audio/mpeg"
                                />
                                Your browser does not support the mashqAudioPath
                                element.
                              </audio>
                            </div>
                          </div>

                          <div className="col-md-6 mb-3">
                            <div className="mb-2">
                              <label htmlFor="" className="key">
                                Sub Sabaq Description
                              </label>
                            </div>
                            <div className="mb-2">
                              <label htmlFor="" className="value">
                                {element?.subSabaqDesc}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <div className="mb-2">
                              <label htmlFor="" className="key">
                                Sub Sabaq Description Audio
                              </label>
                            </div>
                            <div>
                              <audio
                                controls
                                style={{ width: "100%", height: "38px" }}
                              >
                                <source
                                  src={element?.descAudioPath}
                                  type="audio/ogg"
                                />
                                <source
                                  src={element?.descAudioPath}
                                  type="audio/mpeg"
                                />
                                Your browser does not support the mashqAudioPath
                                element.
                              </audio>
                            </div>
                          </div>

                          <h6
                            className="fw-bold mb-4 "
                            style={{ textDecoration: "underline" }}
                          >
                            Mashq Details
                          </h6>
                          {element?.mashqs && element?.mashqs.length > 0
                            ? element?.mashqs.map((sub, index) => {
                                return (
                                  <>
                                    <div
                                      key={"test" + index}
                                      className="col-md-6 mb-3"
                                    >
                                      <div>
                                        <label htmlFor="" className="key">
                                          Mashq Arbic
                                        </label>
                                      </div>
                                      <div>
                                        <label htmlFor="" className="value">
                                          {sub?.mashq}
                                        </label>
                                      </div>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                      <div>
                                        <label htmlFor="" className="key">
                                          Mashq Audio
                                        </label>
                                      </div>
                                      <div>
                                        <audio
                                          controls
                                          style={{
                                            width: "100%",
                                            height: "38px",
                                          }}
                                        >
                                          <source
                                            src={sub?.mashqAudioPath}
                                            type="audio/ogg"
                                          />
                                          <source
                                            src={sub?.mashqAudioPath}
                                            type="audio/mpeg"
                                          />
                                          Your browser does not support the
                                          mashqAudioPath element.
                                        </audio>
                                      </div>
                                    </div>
                                  </>
                                );
                              })
                            : ""}
                        </div>
                      );
                    })
                  : ""}
              </div>
              <div className="buttons-modal border-top pt-3 text-end ">
                <Link
                  to="#"
                  className="bg-danger"
                  onClick={() => {
                    handleViewClose();
                  }}
                >
                  Cancel
                </Link>
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        {/* ======================= END VIEW ========================== */}

        {loader ? (
          <FormLoader />
        ) : (
          <div className="table-wrapper">
            <h6>Total Records : {sabaqList.length}</h6>
            <Table className="display" striped bordered hover size="lg">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th style={{ textAlign: "left" }}>Sabaq List</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {sabaqList && sabaqList.length > 0
                  ? sabaqList.slice(startIndex, endIndex).map((ele, index) => {
                      return (
                        <React.Fragment>
                          <tr key={"indexele" + index}>
                            <td>{index + 1 + startIndex}</td>
                            <td>{ele.sabaqName}</td>
                            <td className="action">
                              <Link
                                to="#"
                                className="icon edit"
                                onClick={() => {
                                  // handleAddShow();
                                  setIdEdit("edit");
                                  setImageWidth({
                                    ...imageWidth,
                                    sabaq: "117px",
                                    des: "117px",
                                    Title: "117px",
                                    subdesc: "117px",
                                    mashq: "117px",
                                  });
                                  EditListApi(ele.sabaqId);
                                }}
                              >
                                <img src={ic_edit} alt="" />
                              </Link>
                              <Link
                                to="#"
                                className="icon view"
                                // onClick={handleViewShow}
                                onClick={() => {
                                  // handleViewShow();
                                  // EditListApi(ele.sabaqId, "view");
                                  // setViewDetails(viewSabaqList);
                                  // setAddSabaq(filterEdit)
                                  viewDetailshandle(ele.sabaqId);
                                }}
                              >
                                <img src={ic_eyes} alt="" />
                              </Link>
                              <Link
                                to="#"
                                className="icon delete"
                                onClick={() => {
                                  handleDeleteShow();
                                  setDeleteId(ele.sabaqId);
                                }}
                              >
                                <img src={ic_delete} alt="" />
                              </Link>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })
                  : ""}
              </tbody>
            </Table>

            {sabaqList && sabaqList.length > 0 ? (
              <Pagination
                changePage={changePage}
                pageCount={pageCount}
                currentPage={currentPage}
              />
            ) : (
              <div>
                <h5 style={{ color: "red", textAlign: "center" }}>
                  {" "}
                  Records Not Found
                </h5>
              </div>
            )}
          </div>
        )}
      </main>
    </>
  );
};

export default NoraaniQaaidah;
