import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Dashboard from "./pages/Dashboard";
import "./assets/styles/main.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./sharedComponents/Header";
import Sidebar from "./sharedComponents/Sidebar";
import Login from "./pages/Login";
import DuaSunnat from "./pages/DuaSunnat";
import HifzESurah from "./pages/HifzESurah";
import Masaail from "./pages/Masaail";
import Namaaz from "./pages/Namaaz";
import NoraaniQaaidah from "./pages/NoraaniQaaidah";
import Seerat from "./pages/Seerat";
import UmrahHajj from "./pages/UmrahHajj";
import Aqaaid from "./pages/Aqaaid";
import PushNotification from "./pages/PushNotification";
import GoogleAnalytics from "./pages/GoogleAnalytics";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Ramzan from "./pages/Ramzan";
import Intro from "./pages/Intro";
import DailyPost from "./pages/DailyPost";
function App() {
  const navigate = useNavigate();
  const location = useLocation();
  let currentRoute = location.pathname;
  const [logedIn, setLoggedIn] = useState(
    localStorage.getItem("logedIn") ? localStorage.getItem("logedIn") : false
  );
  // let logedIn = localStorage.getItem("logedIn") ? localStorage.getItem("logedIn") : false

  useEffect(() => {
    if (!logedIn) {
      navigate("/");
    } else {
      navigate("/Dashboard");
    }
  }, [logedIn]);
  useEffect(() => {
    if (!logedIn && currentRoute != "/") {
      navigate("/");
    } else {
      navigate(currentRoute);
    }
  }, [currentRoute]);
  return (
    <div className="App">
      <ToastContainer />
      {currentRoute !== "/" && (
        <>
          <Header setLoggedIn={setLoggedIn} />
          <Sidebar />
        </>
      )}

      <Routes>
        <Route path="/" element={<Login setLoggedIn={setLoggedIn} />} />
        <Route path="Dashboard" element={<Dashboard />} />
        <Route path="DuaSunnat" element={<DuaSunnat />} />
        <Route path="HifzESurah" element={<HifzESurah />} />
        <Route path="Masaail" element={<Masaail />} />
        <Route path="Ramzan" element={<Ramzan />} />
        <Route path="Namaaz" element={<Namaaz />} />
        <Route path="NoraaniQaaidah" element={<NoraaniQaaidah />} />
        <Route path="Seerat" element={<Seerat />} />
        <Route path="UmrahHajj" element={<UmrahHajj />} />
        <Route path="Aqaaid" element={<Aqaaid />} />
        <Route path="PushNotification" element={<PushNotification />} />
        <Route path="GoogleAnalytics" element={<GoogleAnalytics />} />
        <Route path="Intro" element={<Intro />} />
        <Route path="dailyPost" element={<DailyPost />} />


      </Routes>
    </div>
  );
}
// test
//test2
export default App;
