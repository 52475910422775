import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";

import ic_delete from "../assets/images/ic_delete.svg";
import ic_edit from "../assets/images/ic_edit.svg";
import ic_eyes from "../assets/images/ic_eyes.svg";
import Modal from "react-bootstrap/Modal";
import { ToastMsg } from "./TostMsg";
import ApiConfig from "../api/ApiConfig";
import {
  DeleteCallWithErrorResponse,
  multipartPostCallWithErrorResponse,
  PostCallWithAuthTokenWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../api/ApiServices";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Pagination } from "./Pagination";
import FormLoader from "./FormLoader";
const Ramzan = () => {
  const [AddNewGroup, setAddNewGroup] = useState(0);
  const [idEdit, setIdEdit] = useState("");
  const { sidebar, adminData } = useContext(AppContext);
  const [loader, setLoader] = useState(true);
  const [viewDetails, setViewDetails] = useState({});
  const [imageWidth, setImageWidth] = useState({
    sabaqAudio: "100%",
    Uploade: "100%",
    Uploade1: "100%",
    Tarjama: "100%",
    Description: "100%",
    subSabaqTitle: "100%",
  });
  const [addNamaz, setAddNamaz] = useState({
    sabaqName: "",
    subSabaq: [
      {
        subSabaqTitle: "",
        subSabaqTitleAudio: "",
        subSabaqDesc: "",
        subSabaqDescAudio: "",
        subsabaqImage: "",
        subSabaqImgVdoPath: "",
        isJumma: false

      },
    ],
  });

  const [add, setAdd] = useState(false);
  const handleAddClose = () => {
    setIdEdit("");
    setAddNamaz({
      sabaqName: "",
      sabaqDesc: "",
      sabaqDescAudio: "",
      sabaqNameAudio: "",
      // sabaqDescReference: "",

      subSabaq: [
        {
          subSabaqTitle: "",
          subSabaqTitleAudio: "",
          subSabaqDesc: "",
          subSabaqDescAudio: "",
          subSabaqDescReference: "",
          subsabaqImage: "",
          isJumma: false
        },
      ],
    });
    setAdd(false);
  };
  const handleAddShow = () => setAdd(true);
  const [lasatSabaqId, setLsatSabaq] = useState("");
  const [saveBotton, setSaveBotton] = useState(false);




  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDelete] = useState(false);
  const handleDeleteClose = () => setDelete(false);
  const handleDeleteShow = () => setDelete(true);

  const [viewModal, setView] = useState(false);
  const handleViewClose = () => setView(false);
  const handleViewShow = () => setView(true);

  const [validated, setValidated] = useState(false);
  const [masaiList, setMasaiList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const startIndex = currentPage * 15;
  const endIndex = startIndex + 15;
  const changePage = ({ selected }) => {
    setCurrentPage(selected);
  };
  const pageCount = Math.ceil(masaiList?.length / 15);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      addSabaqApi();
    }

    setValidated(true);
  };

  //add
  const addSabaqApi = () => {
    // setSaveBotton(true)
    handleAddClose();
    setLoader(true);
    const body = {
      categoryId: 10,
      userId: Number(adminData.user_id),
      sabaqNo: lasatSabaqId + 1,
      sabaqName: addNamaz.sabaqName ? addNamaz.sabaqName : "",
      sabaqDesc: addNamaz.sabaqDesc ? addNamaz.sabaqDesc : "",
      sabaqId: addNamaz.SabaqId,
    };
    let payLoadFormData = new FormData();
    payLoadFormData.append("categoryId", body.categoryId);
    payLoadFormData.append("userId", body.userId);
    payLoadFormData.append("sabaqName", body.sabaqName);

    idEdit === "edit"
      ? payLoadFormData.append("sabaqId", body.sabaqId)
      : payLoadFormData.append("sabaqNo", body.sabaqNo);

    let subSabaq = addNamaz.subSabaq.map((ele, index) => {
      return {

        subSabaqNo: index + 1,
        subSabaqTitle: ele.subSabaqTitle ? ele.subSabaqTitle : "",
        subSabaqDesc: ele.subSabaqImgVdoPath ? ele.subSabaqImgVdoPath : "",
        subSabaqTitleAudio: ele.subsabaqImage == "" ? "no file" : "file name",
        subSabaqDescAudio:
          ele.subSabaqDescAudio == "" ? "no file" : "file name",
        subSabaqImgVdo: ele.subSabaqTitleAudio == "" ? "no file" : "file name",
        isJumma: ele.isJumma
      };
    });
    let subSabaqEdit = addNamaz.subSabaq.map((ele, index) => {
      return {
        subSabaqId: ele.subSabaqId,
        subSabaqNo: index + 1,
        subSabaqTitle: ele.subSabaqTitle ? ele.subSabaqTitle : "",
        subSabaqDescAudio:
          typeof ele.subSabaqDescAudio === "object" ? "updated" : "not updated",
        subSabaqTitleAudio:
          typeof ele.subsabaqImage === "object" ? "updated" : "not updated",
        subSabaqDesc: ele.subSabaqImgVdoPath ? ele.subSabaqImgVdoPath : "",
        subSabaqImgVdo:
          typeof ele.subSabaqTitleAudio === "object"
            ? "updated"
            : "not updated",
        isJumma: ele.isJumma,

      };
    });

    idEdit === "edit"
      ? payLoadFormData.append("subSabaq", JSON.stringify(subSabaqEdit))
      : payLoadFormData.append("subSabaq", JSON.stringify(subSabaq));
    addNamaz.subSabaq.map((ele) =>
      payLoadFormData.append("subSabaqDescAudio", ele.subSabaqDescAudio ? ele.subSabaqDescAudio : "")
    );

    addNamaz.subSabaq.map((ele) =>
      payLoadFormData.append(
        "subSabaqTitleAudio",
        ele.subsabaqImage ? ele.subsabaqImage : ""
      )
    );

    addNamaz.subSabaq.map((ele) =>
      payLoadFormData.append("subSabaqImgVdo", ele.subSabaqTitleAudio ? ele.subSabaqTitleAudio : "")
    );

    setLoader(true);

    idEdit === "edit"
      ? putMultipartWithAuthCallWithErrorResponse(
        ApiConfig.SUBAQUPDATE,
        payLoadFormData
      )
        .then((res) => {
          if (res.json && res.json?.success === true) {
            ToastMsg("success", res.json.message);
            ramzanListApi();
            // setSaveBotton(false)
          } else {
            ToastMsg("error", res.json.message);
          }
        })
        .catch((err) => {
          setLoader(false);

          ToastMsg("error", "something went wrong");
        })
      : multipartPostCallWithErrorResponse(
        ApiConfig.SUBAQADDALL,
        payLoadFormData
        // payLoadFormDataNew
      )
        .then((res) => {
          if (res.json && res.json?.success === true) {
            ToastMsg("success", res.json.message);
            ramzanListApi();
            setSaveBotton(false);
          } else {
            ToastMsg("error", res.json.message);
          }
        })
        .catch((err) => {
          setLoader(false);

          ToastMsg("error", "something went wrong");
        });
  };
  useEffect(() => {
    ramzanListApi();
  }, []);
  //
  const ramzanListApi = () => {
    // setIsLoading(true);

    simpleGetCallWithErrorResponse(ApiConfig.SUBAQLIST + `?categoryId=${10}`)
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          setMasaiList(res.json?.data?.allSabaq);
          setLsatSabaq(res.json?.data.allSabaq.length);

          // ToastMsg("success", res.json.message);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  const ramzanDeleteApi = () => {
    const body = {
      categoryId: 10,

      sabaqId: deleteId,
    };
    // setIsLoading(true);
    DeleteCallWithErrorResponse(
      ApiConfig.DELETEAPI,

      JSON.stringify({
        ...body,
      })
    )
      .then((res) => {
        setLoader(false);

        handleDeleteClose();
        if (res.json && res.json?.success === true) {
          ToastMsg("success", res.json.message);
          ramzanListApi();
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };
  //
  const EditListApi = (id, param) => {
    simpleGetCallWithErrorResponse(ApiConfig.EDITALL + `?sabaqId=${id}`)
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          let data = res.json?.data;
          setAddNamaz({
            SabaqId: data?.SabaqId,
            sabaqName: data?.sabaqName ? data?.sabaqName : "",

            subSabaq:
              data &&
              data.subSabaqs.map((ele, index) => {
                return {
                  subSabaqId: ele.subSabaqId,
                  subSabaqNo: ele.subSabaqNo ? ele.subSabaqNo : index + 1,
                  subSabaqTitle: ele.subSabaqTitle ? ele.subSabaqTitle : "",
                  subSabaqImgVdoPath: ele.subSabaqDesc ? ele.subSabaqDesc : "",
                  subSabaqDescAudio: ele.descAudioPath ? ele.descAudioPath : "",
                  subSabaqTitleAudio: ele.imagePath ? ele.imagePath : "",
                  subsabaqImage: ele.titleAudioPath ? ele.titleAudioPath : "",
                  isJumma: ele.isJumma
                };
              }),
          });

          handleAddShow();
          // ToastMsg("success", res.json.message);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };
  //view the
  const viewDetailshandle = (id) => {
    simpleGetCallWithErrorResponse(ApiConfig.EDITALL + `?sabaqId=${id}`)
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          setViewDetails(res.json.data);
          handleViewShow();
          // ToastMsg("success", res.json.message);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  const nevigation = useNavigate()

  const routOnIntro = () => {
    nevigation("/Intro")
  }

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Ramzan</label>
        </div>


        <div className="add-btn">

          <button onClick={handleAddShow}>
            Add Sabaq
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path fill="white" d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" />
            </svg>
          </button>
        </div>
      </div>
      {/* ======================= START ADD ========================== */}
      <Modal
        show={add}
        // onHide={handleAddClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton onHide={handleAddClose}>
          <Modal.Title>Add Sabaq</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12 mb-3">
                <Form.Label>Sabaq Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={addNamaz?.sabaqName}
                  onChange={(e) =>
                    setAddNamaz({ ...addNamaz, sabaqName: e.target.value })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Sabaq Name
                </Form.Control.Feedback>
              </div>


              {addNamaz.subSabaq &&
                addNamaz.subSabaq.map((elementInArray, index) => {
                  return (
                    <div className="col-md-12 sub-group-main">
                      <div className="row">
                        <h6 className="fw-bold">Sub Sabaq Details</h6>
                        <div className="col-md-12 mb-3">
                          <Form.Label>
                            Title <span>{index + 1}</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={elementInArray?.subSabaqTitle}
                            onChange={(e) => {
                              setAddNamaz({
                                ...addNamaz,
                                subSabaq: addNamaz.subSabaq.map(
                                  (item, innerIndex) => {
                                    return index === innerIndex
                                      ? {
                                        ...item,
                                        subSabaqTitle: e.target.value,
                                      }
                                      : item;
                                  }
                                ),
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Sabaq Name
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-6 mb-3">
                          <Form.Label>Video Url</Form.Label>
                          <Form.Control
                            type="text"
                            value={elementInArray?.subSabaqImgVdoPath}
                            onChange={(e) => {
                              setAddNamaz({
                                ...addNamaz,
                                subSabaq: addNamaz.subSabaq.map(
                                  (item, innerIndex) => {
                                    return index === innerIndex
                                      ? {
                                        ...item,
                                        subSabaqImgVdoPath: e.target.value,
                                      }
                                      : item;
                                  }
                                ),
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Choose Audio File.
                          </Form.Control.Feedback>
                        </div>

                        {idEdit ? (
                          <>
                            <div className="row d-flex">
                              <div className="col-md-6">
                                <label htmlFor="" className="key">
                                  Image
                                </label>
                              </div>
                              <div
                                className={
                                  imageWidth === "100%" ? "d-none" : "col-md-6 "
                                }
                              >
                                <Form.Label>
                                  {typeof elementInArray?.subSabaqTitleAudio ===
                                    "string"
                                    ? elementInArray?.subSabaqTitleAudio.substring(
                                      elementInArray?.subSabaqTitleAudio
                                        .length - 15,
                                      elementInArray?.subSabaqTitleAudio
                                        .length
                                    )
                                    : ""}
                                </Form.Label>
                              </div>
                              <div className="col-md-12 mb-3 ">
                                <Form.Control
                                  type="file"
                                  name="subSabaqTitleAudio"
                                  style={{ width: imageWidth.subSabaqTitle }}
                                  accept="image/x-png,image/gif,image/jpeg"
                                  onChange={(e) => {
                                    setImageWidth({
                                      ...imageWidth,
                                      subSabaqTitle: "100%",
                                    });
                                    setAddNamaz({
                                      ...addNamaz,
                                      subSabaq: addNamaz.subSabaq.map(
                                        (item, innerIndex) => {
                                          return index === innerIndex
                                            ? {
                                              ...item,
                                              subSabaqTitleAudio:
                                                e.target.files[0],
                                            }
                                            : item;
                                        }
                                      ),
                                    });
                                  }}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Sabaq Name
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-6 mb-3">
                            <Form.Label> Image </Form.Label>
                            <Form.Control
                              type="file"
                              name="sabaqImagePath"
                              accept="image/x-png,image/gif,image/jpeg"
                              //  / value={addSabaq?.sabaqAudio}
                              onChange={(e) => {
                                setAddNamaz({
                                  ...addNamaz,
                                  subSabaq: addNamaz.subSabaq.map(
                                    (item, innerIndex) => {
                                      return index === innerIndex
                                        ? {
                                          ...item,
                                          subSabaqTitleAudio:
                                            e.target.files[0],
                                        }
                                        : item;
                                    }
                                  ),
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Sabaq Name
                            </Form.Control.Feedback>
                          </div>
                        )}

                        {idEdit ? (
                          <>
                            <div className="row d-flex">
                              <div className="col-md-6">
                                <label htmlFor="" className="key">
                                  Audio
                                </label>
                              </div>
                              <div
                                className={
                                  imageWidth === "100%" ? "d-none" : "col-md-6 "
                                }
                              >
                                <Form.Label>
                                  {typeof elementInArray?.subsabaqImage ===
                                    "string"
                                    ? elementInArray?.subsabaqImage.substring(
                                      elementInArray?.subsabaqImage.length -
                                      15,
                                      elementInArray?.subsabaqImage.length
                                    )
                                    : ""}
                                </Form.Label>
                              </div>
                              <div className="col-md-12 mb-3 ">
                                <Form.Control
                                  type="file"
                                  name="sabaqImage"
                                  // accept="image/x-png,image/gif,image/jpeg"
                                  accept="audio/*"
                                  inputProps={{ accept: "image/*" }}
                                  style={{ width: imageWidth.Uploade1 }}
                                  onChange={(e) => {
                                    setImageWidth({
                                      ...imageWidth,
                                      Uploade1: "100%",
                                    });

                                    setAddNamaz({
                                      ...addNamaz,
                                      subSabaq: addNamaz.subSabaq.map(
                                        (item, innerIndex) => {
                                          return index === innerIndex
                                            ? {
                                              ...item,
                                              subsabaqImage:
                                                e.target.files[0],
                                            }
                                            : item;
                                        }
                                      ),
                                    });
                                  }}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Sabaq Name
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-6 mb-3">
                            <Form.Label>Audio</Form.Label>
                            <Form.Control
                              type="file"
                              name="sabaqImage"
                              // accept="image/x-png,image/gif,image/jpeg"
                              accept="audio/*"
                              //  / value={addSabaq?.sabaqAudio}
                              onChange={(e) => {
                                setAddNamaz({
                                  ...addNamaz,
                                  subSabaq: addNamaz.subSabaq.map(
                                    (item, innerIndex) => {
                                      return index === innerIndex
                                        ? {
                                          ...item,
                                          subsabaqImage: e.target.files[0],
                                        }
                                        : item;
                                    }
                                  ),
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Sabaq Name
                            </Form.Control.Feedback>
                          </div>
                        )}
                        {/* <div className="col-md-6 mb-3">
                        </div> */}
                        <div className="col-md-1 mt-5">
                          <div className="checkBox">
                            <div className="form-check form-switch">
                              <input
                                type="checkbox"
                                // value={elementInArray?.isJumma}

                                checked={elementInArray?.isJumma}
                                onChange={(e) => {
                                  setAddNamaz({
                                    ...addNamaz,
                                    subSabaq: addNamaz.subSabaq.map(
                                      (item, innerIndex) => {
                                        console.log("isjumma", index)
                                        console.log("isjummainnerIndex", innerIndex)

                                        return index === innerIndex
                                          ? {
                                            ...item,
                                            isJumma:
                                              e.target.checked,
                                          }
                                          : item;
                                      }
                                    ),
                                  });
                                }}
                                className="form-check-input"
                                role="switch" id={`flexSwitchCheckChecked${index}`}
                              />
                            </div>
                          </div>

                        </div>
                        {
                          elementInArray.isJumma && (
                            <>
                              {idEdit ? (
                                <>
                                  <div className="row d-flex">
                                    <div className="col-md-4">
                                      <label htmlFor="" className="key">
                                        Jumma Khudba
                                      </label>
                                    </div>
                                    <div
                                      className={
                                        imageWidth === "100%"
                                          ? "d-none"
                                          : "col-md-6 "
                                      }
                                    >
                                      <Form.Label>
                                        {typeof elementInArray?.subSabaqDescAudio ===
                                          "string"
                                          ? elementInArray?.subSabaqDescAudio.substring(
                                            elementInArray?.subSabaqDescAudio
                                              .length - 15,
                                            elementInArray?.subSabaqDescAudio
                                              .length
                                          )
                                          : ""}
                                      </Form.Label>
                                    </div>
                                    <div className="col-md-12 mb-3 ">
                                      <Form.Control
                                        type="file"
                                        name="sabaqImagePath"
                                        accept="audio/*"

                                        // accept="image/x-png,image/gif,image/jpeg"
                                        style={{ width: imageWidth.Description }}
                                        onChange={(e) => {
                                          setImageWidth({
                                            ...imageWidth,
                                            Description: "100%",
                                          });
                                          setAddNamaz({
                                            ...addNamaz,
                                            subSabaq: addNamaz.subSabaq.map(
                                              (item, innerIndex) => {
                                                return index === innerIndex
                                                  ? {
                                                    ...item,
                                                    subSabaqDescAudio:
                                                      e.target.files[0],
                                                  }
                                                  : item;
                                              }
                                            ),
                                          });
                                        }}
                                      />

                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Sabaq Name
                                      </Form.Control.Feedback>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div className="col-md-5 mb-3">
                                  <Form.Label>Jumma Khudba</Form.Label>
                                  <Form.Control
                                    type="file"
                                    name="sabaqImagePath"
                                    accept="audio/*"
                                    // accept="image/x-png,image/gif,image/jpeg"
                                    //  / value={addSabaq?.sabaqAudio}
                                    // inputProps={{ accept: "image/*" }}
                                    onChange={(e) => {
                                      setAddNamaz({
                                        ...addNamaz,
                                        subSabaq: addNamaz.subSabaq.map(
                                          (item, innerIndex) => {
                                            return index === innerIndex
                                              ? {
                                                ...item,
                                                subSabaqDescAudio:
                                                  e.target.files[0],
                                              }
                                              : item;
                                          }
                                        ),
                                      });
                                    }}
                                  />

                                  <Form.Control.Feedback type="invalid">
                                    Please Enter Sabaq Name
                                  </Form.Control.Feedback>
                                </div>
                              )}
                            </>
                          )
                        }

                      </div>
                      <div className="text-end">
                        <Link
                          to="#"
                          // className="btn btn-danger"
                          // onClick={() => setAddNewGroup(AddNewGroup - 1)}
                          className={
                            addNamaz.subSabaq.length > 1
                              ? "btn btn-danger"
                              : "btn btn-secondary invisible"
                          }
                          onClick={() => {
                            if (addNamaz.subSabaq.length > 1) {
                              // addNamaz.subSabaq.splice(index, 1);
                              const copySubDeatails = [...addNamaz.subSabaq];
                              copySubDeatails.splice(index, 1);
                              setAddNamaz({
                                ...addNamaz,
                                subSabaq: copySubDeatails,
                              });
                            }

                            // const copySubDeatails = [...addSabaq.subsabaqDetails];
                            // copySubDeatails.sli
                          }}
                        >
                          Remove
                        </Link>
                      </div>
                    </div>
                  );
                })}

              <Link
                to="#"
                className={
                  // idEdit
                  //   ? "add-newSabaq-btn invisible btn btn-secondary pe-none"
                  //   :
                  "add-newSabaq-btn btn btn-primary"
                }
                // onClick={() => setAddNewGroup(AddNewGroup + 1)}
                onClick={() => {
                  let namaz = [
                    ...addNamaz.subSabaq,

                    {
                      subSabaqTitle: "",
                      subSabaqTitleAudio: "",
                      subSabaqDesc: "",
                      subSabaqDescAudio: "",
                      subSabaqDescReference: "",
                      subsabaqImage: "",
                    },
                  ];
                  setAddNamaz({
                    ...addNamaz,
                    subSabaq: namaz,
                  });
                }}
              >
                + Add Sub Sabaq Details
              </Link>
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <button className="me-2" type="submit">
                {idEdit === "edit" ? "update" : "Save"}
              </button>
              <Link
                to="#"
                className=" bg-danger"
                onClick={() => {
                  setAddNamaz({
                    sabaqName: "",
                    sabaqDesc: "",
                    sabaqDescAudio: "",
                    sabaqNameAudio: "",

                    sabaqDescReference: "",

                    subSabaq: [
                      {
                        subSabaqTitle: "",
                        subSabaqTitleAudio: "",
                        subSabaqDesc: "",
                        subSabaqDescAudio: "",
                        subSabaqDescReference: "",
                      },
                    ],
                  });
                  handleAddClose();
                  setIdEdit("");
                }}
              >
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END ADD ========================== */}

      {/* ======================= DELETE ========================== */}

      <Modal
        show={deleteModal}
        onHide={handleDeleteClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">Are you sure you want to delete this item?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button
              className="me-2 bg-danger"
              type="submit"
              onClick={() => {
                handleDeleteClose()
                ramzanDeleteApi()
              }}
            >
              Delete
            </button>
            <Link to="#" className="" onClick={handleDeleteClose}>
              Cancel
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
      {/* ======================= END DELETE ========================== */}

      {/* ======================= VIEW ========================== */}
      <Modal
        show={viewModal}
        onHide={handleViewClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Ramazan Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Sabaq No.
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewDetails?.SabaqId}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Sabaq Name
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewDetails?.sabaqName}
                  </label>
                </div>
              </div>

              <h5 className="fw-bold">Sub Sabaq Details</h5>
              {viewDetails.subSabaqs &&
                viewDetails.subSabaqs.map((ele, index) => {
                  return (
                    <div className="sub-group-main row">
                      <div className="col-md-12 mb-3">
                        <div>
                          <label htmlFor="" className="key">
                            Title {index + 1}
                          </label>
                        </div>
                        <div>
                          <label htmlFor="" className="value">
                            {ele.subSabaqTitle}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div>
                          <label htmlFor="" className="key">
                            Image
                          </label>
                        </div>
                        <div>
                          <MDBRow>
                            <MDBCol lg="4" md="6" className="mb-4">
                              <img
                                src={
                                  !ele?.imagePath
                                    ? ""
                                    : ele?.imagePath.length
                                      ? ele?.imagePath
                                      : ele?.imagePath &&
                                      URL.createObjectURL(ele?.imagePath)
                                }
                                className="img-fluid rounded value"
                                alt=" No Image"
                              />
                            </MDBCol>
                          </MDBRow>
                        </div>
                      </div>
                      <>
                        <div className="col-md-12 mb-3">
                          <div className="mb-2">
                            <label htmlFor="" className="key">
                              Video
                            </label>
                          </div>
                          <div>
                            <video
                              controls
                              style={{ width: "320px", height: "240px" }}
                            >
                              <source src={ele.subSabaqDesc} type="video/mp4" />
                              <source src={ele.subSabaqDesc} type="video/ogg" />
                              Your browser does not support the audio element.
                            </video>
                          </div>
                        </div>
                      </>

                      <h4 className="my-3">OR</h4>
                      <div className="col-md-12 mb-3">
                        <div>
                          <label htmlFor="" className="key">
                            Audio
                          </label>
                        </div>
                        {ele?.titleAudioPath ? (
                          <div className="mt-3">
                            <MDBRow>
                              <MDBCol lg="4" md="6" className="mb-4">

                                <audio controls style={{ width: "100%", height: "38px" }}>
                                  <source src={ele?.titleAudioPath} type="audio/ogg" />
                                  <source src={ele?.titleAudioPath} type="audio/mpeg" />
                                  Your browser does not support the audio element.
                                </audio>
                              </MDBCol>
                            </MDBRow>
                          </div>
                        ) : ("")}

                      </div>
                      {
                        ele?.isJumma === true ? (
                          <div className="col-md-12 mb-3">
                            <div>
                              <label htmlFor="" className="key">
                                Jumma Khudba
                              </label>
                            </div>
                            <div>
                              {ele?.descAudioPath ? (
                                <div className="mt-3">
                                  <MDBRow>
                                    <MDBCol lg="4" md="6" className="mb-4">
                                      <audio controls style={{ width: "100%", height: "38px" }}>
                                        <source src={ele?.descAudioPath} type="audio/ogg" />
                                        <source src={ele?.descAudioPath} type="audio/mpeg" />
                                        Your browser does not support the audio element.
                                      </audio>
                                    </MDBCol>
                                  </MDBRow>
                                </div>
                              ) : ("")}

                            </div>
                          </div>
                        ) : ("")
                      }

                    </div>
                  );
                })}
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <Link to="#" className="bg-danger" onClick={handleViewClose}>
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END VIEW ========================== */}
      {loader ? (
        <FormLoader />
      ) : (
        <div className="table-wrapper">
          <h6>Total Records : {masaiList.length}</h6>
          <Table className="display" striped bordered hover size="lg">
            <thead>
              <tr>
                <th>Sabaq No.</th>
                <th style={{ textAlign: "left" }}>Sabaq List</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {masaiList && masaiList.length > 0
                ? masaiList.slice(startIndex, endIndex).map((ele, index) => {
                  return (
                    <tr>
                      <td>{index + 1 + startIndex}</td>

                      <td style={{ textAlign: "left" }}>{ele.sabaqName}</td>
                      <td className="action">
                        <Link
                          to="#"
                          className="icon edit"
                          onClick={() => {
                            // handleAddShow();
                            setIdEdit("edit");
                            setImageWidth({
                              ...imageWidth,
                              sabaqAudio: "117px",
                              Uploade: "117px",
                              Uploade1: "117px",
                              Tarjama: "117px",
                              Description: "117px",
                              subSabaqTitle: "117px",
                            });
                            EditListApi(ele.sabaqId);
                          }}
                        >
                          <img src={ic_edit} alt="" />
                        </Link>
                        <Link
                          to="#"
                          className="icon view"
                          onClick={() => viewDetailshandle(ele.sabaqId)}
                        >
                          <img src={ic_eyes} alt="" />
                        </Link>
                        <Link
                          to="#"
                          className="icon delete"
                          onClick={() => {
                            handleDeleteShow();
                            setDeleteId(ele.sabaqId);
                          }}
                        >
                          <img src={ic_delete} alt="" />
                        </Link>
                      </td>
                    </tr>
                  );
                })
                : ""}
            </tbody>
          </Table>
          {masaiList && masaiList.length > 0 ? (
            <Pagination
              changePage={changePage}
              pageCount={pageCount}
              currentPage={currentPage}
            />
          ) : (
            <div>
              <h5 style={{ color: "red", textAlign: "center" }}>
                {" "}
                Records Not Found
              </h5>
            </div>
          )}
        </div>
      )}
    </main>
  );
};

export default Ramzan;
