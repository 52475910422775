import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";

import ic_delete from "../assets/images/ic_delete.svg";
import ic_edit from "../assets/images/ic_edit.svg";
import ic_eyes from "../assets/images/ic_eyes.svg";
import Modal from "react-bootstrap/Modal";
import { ToastMsg } from "./TostMsg";
import ApiConfig from "../api/ApiConfig";
import {
    DeleteCallWithErrorResponse,
    multipartPostCallWithErrorResponse,
    PostCallWithAuthTokenWithErrorResponse,
    putMultipartWithAuthCallWithErrorResponse,
    simpleGetCallWithErrorResponse,
} from "../api/ApiServices";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Pagination } from "./Pagination";
import FormLoader from "./FormLoader";

const DailyPost = () => {
    const [AddNewGroup, setAddNewGroup] = useState(0);
    const [idEdit, setIdEdit] = useState("");
    const { sidebar, adminData } = useContext(AppContext);
    const [loader, setLoader] = useState(true);
    const [viewDetails, setViewDetails] = useState({});
    const [introId, setIntroId] = useState("")
    const [imageWidth, setImageWidth] = useState({
        sabaqAudio: "100%",
        Uploade: "100%",
        Uploade1: "100%",
        Tarjama: "100%",
        Description: "100%",
        subSabaqTitle: "100%",
    });
    const [addNamaz, setAddNamaz] = useState({
        postDate: "",
        postFilePath: "",
        postId: ""

    });

    const [formatedDate, setFormatedDate] = useState("")
    console.log("datevalue", addNamaz?.postDate)

    const [add, setAdd] = useState(false);
    const handleAddClose = () => {
        setIdEdit("");
        setAddNamaz({
            postFilePath: "",
            postDate: "",
            // intro_file_path: "",
            // introId: null
        });
        setAdd(false);
    };
    const handleAddShow = () => setAdd(true);
    const [lasatSabaqId, setLsatSabaq] = useState("");
    const [saveBotton, setSaveBotton] = useState(false);




    const [deleteId, setDeleteId] = useState("");
    const [deleteModal, setDelete] = useState(false);
    const handleDeleteClose = () => setDelete(false);
    const handleDeleteShow = () => setDelete(true);

    const [viewModal, setView] = useState(false);
    const handleViewClose = () => setView(false);
    const handleViewShow = () => setView(true);

    const [validated, setValidated] = useState(false);
    const [masaiList, setMasaiList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const startIndex = currentPage * 15;
    const endIndex = startIndex + 15;
    const changePage = ({ selected }) => {
        setCurrentPage(selected);
    };
    const formatDate = (dateString) => {
        const dateObject = new Date(dateString);
        const year = dateObject.getFullYear();
        const month = ('0' + (dateObject.getMonth() + 1)).slice(-2); // Months are zero-based
        const day = ('0' + dateObject.getDate()).slice(-2);

        return `${year}-${month}-${day}`;
    }
    const pageCount = Math.ceil(masaiList?.length / 15);
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            addSabaqApi();
        }

        setValidated(true);
    };

    //add
    const addSabaqApi = () => {
        handleAddClose();
        setLoader(true);
        const body = {
            categoryId: 11,
            postDate: addNamaz.postDate ? addNamaz.postDate : "",
            postFilePath: addNamaz.postFilePath ? addNamaz.postFilePath : "",
            postId: addNamaz.postId ? addNamaz.postId : ""


        };
        let payLoadFormData = new FormData();
        payLoadFormData.append("categoryId", body.categoryId);
        payLoadFormData.append("postDate", body.postDate);
        payLoadFormData.append("postFilePath", body.postFilePath);
        // idEdit === "edit" ? payLoadFormData.append("postId", body.postId) ||
        //     payLoadFormData.append("categoryId", body.categoryId): "";
        if (idEdit === "edit") {
            payLoadFormData.append("postId", body.postId)
        }

        setLoader(true);

        idEdit === "edit"
            ? putMultipartWithAuthCallWithErrorResponse(
                ApiConfig.UPDATEDAILYPOST,
                payLoadFormData
            )
                .then((res) => {
                    if (res.json && res.json?.success === true) {
                        ToastMsg("success", res.json.message);
                        ramzanListApi();
                        // setSaveBotton(false)
                    } else {
                        ToastMsg("error", res.json.message);
                    }
                })
                .catch((err) => {
                    setLoader(false);

                    ToastMsg("error", "something went wrong");
                })
            : multipartPostCallWithErrorResponse(
                ApiConfig.DAILYPOST,
                payLoadFormData
                // payLoadFormDataNew
            )
                .then((res) => {
                    if (res.json && res.json?.success === true) {
                        ToastMsg("success", res.json.message);
                        ramzanListApi();
                        setSaveBotton(false);
                    } else {
                        ToastMsg("error", res.json.message);
                    }
                })
                .catch((err) => {
                    setLoader(false);

                    ToastMsg("error", "something went wrong");
                });
    };
    useEffect(() => {
        ramzanListApi();
    }, []);
    //
    const ramzanListApi = () => {
        // setIsLoading(true);

        simpleGetCallWithErrorResponse(ApiConfig.LISTDAILYPOST)
            .then((res) => {
                setLoader(false);

                if (res.json && res.json?.success === true) {
                    console.log("DAILYpOSTlIST", res.json?.data)
                    setMasaiList(res.json?.data);
                    setLsatSabaq(res.json?.data.length);

                    // ToastMsg("success", res.json.message);
                } else {
                    ToastMsg("error", res.json.message);
                }
            })
            .catch((err) => {
                setLoader(false);

                ToastMsg("error", "something went wrong");
            });
    };

    const ramzanDeleteApi = () => {

        DeleteCallWithErrorResponse(
            ApiConfig.DELETEDAILYPOST + `${deleteId}`,


        )
            .then((res) => {
                setLoader(false);

                handleDeleteClose();
                if (res.json && res.json?.success === true) {
                    ToastMsg("success", res.json.message);
                    ramzanListApi();
                } else {
                    ToastMsg("error", res.json.message);
                }
            })
            .catch((err) => {
                setLoader(false);

                ToastMsg("error", "something went wrong");
            });
    };
    //
    const EditListApi = (id, param) => {
        console.log("dailyPostdata", id)
        setAddNamaz(id)
        handleAddShow();
    };
    //view the
    const viewDetailshandle = (id) => {
        simpleGetCallWithErrorResponse(ApiConfig.VIEWDAILYPOST + `${id}`)
            .then((res) => {
                setLoader(false);

                setViewDetails(res.json.data);
                if (res.json && res.json?.success === true) {
                    console.log(":viewDailyPost---->", res.json.data)
                    handleViewShow();
                    // ToastMsg("success", res.json.message);
                } else {
                    ToastMsg("error", res.json.message);
                }
            })
            .catch((err) => {
                setLoader(false);

                ToastMsg("error", "something went wrong");
            });
    };

    const formatDateMonth = (dateString) => {
        if (!dateString) return "";

        console.log("dateString", dateString)

        const parts = dateString.split('-');
        const year = parts[0];
        const day = parseInt(parts[2]);
        const monthIndex = parseInt(parts[1]) - 1;
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const monthName = monthNames[monthIndex];
        console.log("month", monthName)

        return `${year}-${monthName}-${day}`;
    };


    // const getCurrentDate = () => {
    //     const today = new Date();
    //     let month = '' + (today.getMonth() + 1);
    //     let day = '' + today.getDate();
    //     const year = today.getFullYear();

    //     if (month.length < 2) month = '0' + month;
    //     if (day.length < 2) day = '0' + day;

    //     return [year, month, day].join('-');
    // };

    return (
        <main
            id="cx-main"
            className={sidebar ? "admin-main" : "cx-active admin-main"}
        >
            <div className="heading-btn-main">
                <div className="heading-common">
                    <label htmlFor="">Daily Post</label>
                </div>
                <div className="add-btn">
                    <button onClick={handleAddShow}>
                        Add Post
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 24 24"
                        >
                            <path fill="white" d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" />
                        </svg>
                    </button>
                </div>
            </div>
            {/* ======================= START ADD ========================== */}
            <Modal
                show={add}
                // onHide={handleAddClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton onHide={handleAddClose}>
                    {idEdit === "edit" ? "Update  Post" : "Add Post"}

                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                {idEdit ? (
                                    <>
                                        <div className="row d-flex">
                                            <div className="col-md-12">
                                                <label htmlFor="" className="key">
                                                    Post File
                                                </label>
                                            </div>

                                            <div className="col-md-12 mb-3 ">
                                                <Form.Control
                                                    type="file"
                                                    name="postFilePath"
                                                    onChange={(e) => {
                                                        setAddNamaz({ ...addNamaz, postFilePath: e.target.files[0] })
                                                    }}
                                                />
                                                <div
                                                    className={imageWidth === "100%" ? "d-none" : "col-md-6 "}
                                                >
                                                    <Form.Label>
                                                        {typeof addNamaz?.postFilePath === "string"
                                                            ? addNamaz?.postFilePath.substring(
                                                                addNamaz?.postFilePath.length - 15,
                                                                addNamaz?.postFilePath.length
                                                            )
                                                            : ""}
                                                    </Form.Label>
                                                </div>
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Sabaq Name
                                                </Form.Control.Feedback>
                                            </div>

                                        </div>
                                    </>
                                ) : (
                                    <div className="col-md-12 mb-3">
                                        <label className="key">Post File</label>
                                        <Form.Control
                                            required
                                            type="file"
                                            // value={addNamaz?.intro_file_path}
                                            // accept="video/mp4 "
                                            onChange={(e) =>
                                                setAddNamaz({ ...addNamaz, postFilePath: e.target.files[0] })
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter File
                                        </Form.Control.Feedback>


                                    </div>
                                )}

                            </div>
                            <div className="col-md-6 mb-3 ">
                                <label className="key">Post Date</label>
                                <Form.Control
                                    type="date"
                                    value={addNamaz?.postDate ? formatDate(addNamaz?.postDate) : ""}
                                    name="postDate"
                                    onChange={(e) => {
                                        setAddNamaz({ ...addNamaz, postDate: e.target.value })
                                    }}
                                />

                                <Form.Control.Feedback type="invalid">
                                    Please Enter Sabaq Name
                                </Form.Control.Feedback>
                            </div>
                        </div>
                        <div className="buttons-modal border-top pt-3 text-end ">
                            <button className="me-2" type="submit">
                                {idEdit === "edit" ? "update" : "Save"}
                            </button>
                            <Link
                                to="#"
                                className=" bg-danger"
                                onClick={() => {
                                    setAddNamaz({
                                        sabaqName: "",
                                        sabaqDesc: "",
                                        sabaqDescAudio: "",
                                        sabaqNameAudio: "",

                                        sabaqDescReference: "",

                                        subSabaq: [
                                            {
                                                subSabaqTitle: "",
                                                subSabaqTitleAudio: "",
                                                subSabaqDesc: "",
                                                subSabaqDescAudio: "",
                                                subSabaqDescReference: "",
                                            },
                                        ],
                                    });
                                    handleAddClose();
                                    setIdEdit("");
                                }}
                            >
                                Cancel
                            </Link>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            {/* ======================= END ADD ========================== */}

            {/* ======================= DELETE ========================== */}

            <Modal
                show={deleteModal}
                onHide={handleDeleteClose}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="delete-msg text-center">
                        <label htmlFor="">Are you sure you want to delete this Post?</label>
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <div className="buttons-modal    ">
                        <button
                            className="me-2 bg-danger"
                            type="submit"
                            onClick={() => {
                                handleDeleteClose()
                                ramzanDeleteApi()
                            }}
                        >
                            Delete
                        </button>
                        <Link to="#" className="" onClick={handleDeleteClose}>
                            Cancel
                        </Link>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* ======================= END DELETE ========================== */}

            {/* ======================= VIEW ========================== */}
            <Modal
                show={viewModal}
                onHide={handleViewClose}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Daily Post</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="row">

                            <div className="col-md-12 mb-3">
                                <div>
                                    <label htmlFor="" className="key">
                                        Daily Post Date
                                    </label>
                                </div>
                                <div>
                                    <label htmlFor="" className="value">
                                        {formatDateMonth(viewDetails?.postDate)}
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <div>
                                    <label htmlFor="" className="key">
                                        Daily Post File
                                    </label>

                                </div>
                                <div>
                                    <img
                                        src={
                                            // !ele?.imagePath
                                            //     ? ""
                                            //     : ele?.imagePath.length
                                            //         ? ele?.imagePath
                                            //         : ele?.imagePath &&
                                            viewDetails?.postFilePath
                                        }
                                        className="img-fluid rounded value"
                                        alt=" No Image"
                                    />

                                </div>
                            </div>
                        </div>
                        <div className="buttons-modal border-top pt-3 text-end ">
                            <Link to="#" className="bg-danger" onClick={handleViewClose}>
                                Cancel
                            </Link>
                        </div>

                    </Form>
                </Modal.Body>
            </Modal>
            {/* ======================= END VIEW ========================== */}
            {loader ? (
                <FormLoader />
            ) : (
                <div className="table-wrapper">
                    <h6>Total Records : {masaiList.length}</h6>
                    <Table className="display" striped bordered hover size="lg">
                        <thead>
                            <tr>
                                <th>Post No.</th>
                                <th style={{ textAlign: "center" }}>Post Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {masaiList && masaiList.length > 0
                                ? masaiList.slice(startIndex, endIndex).map((ele, index) => {
                                    return (
                                        <tr>
                                            <td>{index + 1 + startIndex}</td>

                                            <td style={{ textAlign: "center" }}>{formatDateMonth(ele.postDate)}</td>
                                            <td className="action">
                                                <Link
                                                    to="#"
                                                    className="icon edit"
                                                    onClick={() => {
                                                        // handleAddShow();
                                                        setIdEdit("edit");
                                                        setImageWidth({
                                                            ...imageWidth,
                                                            sabaqAudio: "117px",
                                                            Uploade: "117px",
                                                            Uploade1: "117px",
                                                            Tarjama: "117px",
                                                            Description: "117px",
                                                            subSabaqTitle: "117px",
                                                        });
                                                        EditListApi(ele);
                                                        // handleAddShow();

                                                        setIntroId(ele.postId)
                                                    }}
                                                >
                                                    <img src={ic_edit} alt="" />
                                                </Link>
                                                <Link
                                                    to="#"
                                                    className="icon view"
                                                    onClick={() => viewDetailshandle(ele.postId)}
                                                >
                                                    <img src={ic_eyes} alt="" />
                                                </Link>
                                                <Link
                                                    to="#"
                                                    className="icon delete"
                                                    onClick={() => {
                                                        handleDeleteShow();
                                                        setDeleteId(ele.postId);
                                                    }}
                                                >
                                                    <img src={ic_delete} alt="" />
                                                </Link>
                                            </td>
                                        </tr>
                                    );
                                })
                                : ""}
                        </tbody>
                    </Table>
                    {masaiList && masaiList.length > 0 ? (
                        <Pagination
                            changePage={changePage}
                            pageCount={pageCount}
                            currentPage={currentPage}
                        />
                    ) : (
                        <div>
                            <h5 style={{ color: "red", textAlign: "center" }}>
                                {" "}
                                Records Not Found
                            </h5>
                        </div>
                    )}
                </div>
            )}
        </main>
    );
};

export default DailyPost;
