import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "./../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";

import ic_delete from "../assets/images/ic_delete.svg";
import ic_edit from "../assets/images/ic_edit.svg";
import ic_eyes from "../assets/images/ic_eyes.svg";
import Modal from "react-bootstrap/Modal";
import {
  DeleteCallWithErrorResponse,
  multipartPostCallWithErrorResponse,
  PostCallWithAuthTokenWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { ToastMsg } from "./TostMsg";
import { Pagination } from "./Pagination";
import FormLoader from "./FormLoader";
const UmrahHajj = () => {
  const [AddNewGroup, setAddNewGroup] = useState(0);
  const [idEdit, setIdEdit] = useState("");
  const [viewDetails, setViewDetails] = useState([]);
  const { sidebar, adminData } = useContext(AppContext);
  const [loader, setLoader] = useState(true);
  const [lasatSabaqId, setLsatSabaq] = useState("");
  const[saveBotton, setSaveBotton] =useState(false);


  const [umrahList, setUmrahList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const startIndex = currentPage * 15;
  const endIndex = startIndex + 15;
  const changePage = ({ selected }) => {
    setCurrentPage(selected);
  };
  const pageCount = Math.ceil(umrahList?.length / 15);
  const [imageWidth, setImageWidth] = useState({
    sabaqAudio: "100%",
    Uploade: "100%",
    Tarjama: "100%",
    Description: "100%",
    subSabaqTitle: "100%",
  });
  const [deleteId, setDeleteId] = useState("");
  const [addNamaz, setAddNamaz] = useState({
    sabaqName: "",
    sabaqDesc: "",
    sabaqDescAudio: "",
    sabaqNameAudio: "",
    sabaqDescReference: "",
    subSabaq: [
      {
        subSabaqTitle: "",
        subSabaqTitleAudio: "",
        subSabaqDesc: "",
        subSabaqDescAudio: "",
        subSabaqDescReference: "",
      },
    ],
  });



  const [add, setAdd] = useState(false);
  const handleAddClose = () =>{ 
    setAddNamaz({
      sabaqName: "",
      sabaqDesc: "",
      sabaqDescAudio: "",
      sabaqNameAudio: "",
      sabaqDescReference: "",
      subSabaq: [
        {
          subSabaqTitle: "",
          subSabaqDesc: "",
          subSabaqTitleAudio: "",
          subSabaqDescAudio: "",
          subSabaqDescReference: "",
        },
      ],
    })
    setIdEdit("")
    setAdd(false)}
    const handleAddShow = () => setAdd(true);

  const [deleteModal, setDelete] = useState(false);
  const handleDeleteClose = () => setDelete(false);
  const handleDeleteShow = () => setDelete(true);

  const [viewModal, setView] = useState(false);
  const handleViewClose = () => setView(false);
  const handleViewShow = () => setView(true);

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      addumrahaajApi();
    }

    setValidated(true);
  };

  const addumrahaajApi = () => {
    setSaveBotton(true)
    handleAddClose();
    setLoader(true);
    const body = {
      categoryId: 7,
      userId: Number(adminData.user_id),
      sabaqNo: lasatSabaqId+1,
      sabaqName: addNamaz.sabaqName?addNamaz.sabaqName:'',
      sabaqDesc: addNamaz.sabaqDesc?addNamaz.sabaqDesc:"",
      sabaqDescReference: addNamaz.sabaqDescReference? addNamaz.sabaqDescReference:"",
       sabaqId: addNamaz.SabaqId,
    };
    //all dataa paylodade
    let payLoadFormData = new FormData();
    payLoadFormData.append("categoryId", body.categoryId);
    payLoadFormData.append("userId", body.userId);
    idEdit==="edit" ?payLoadFormData.append("sabaqId", body.sabaqId):payLoadFormData.append("sabaqNo", body.sabaqNo);

    payLoadFormData.append("sabaqName", body.sabaqName);
    payLoadFormData.append("sabaqDesc", body.sabaqDesc);
    payLoadFormData.append("sabaqDescReference", body.sabaqDescReference);
    let subSabaq = addNamaz.subSabaq.map((ele, index) => {
     
      return {
        subSabaqDescReference: ele.subSabaqDescReference? ele.subSabaqDescReference:"",
        subSabaqDesc: ele.subSabaqDesc?ele.subSabaqDesc:"",
        subSabaqNo: index + 1,
        subSabaqTitleAudio: ele.subSabaqTitleAudio== "" ? "no file" : "file name",
        subSabaqDescAudio: ele.subSabaqDescAudio== "" ? "no file" : "file name",
        subSabaqTitle: ele.subSabaqTitle?ele.subSabaqTitle:"",
      };
    });
    let subSabaqEdit = addNamaz.subSabaq.map((ele, index) => {
      return {
        subSabaqDescReference: ele.subSabaqDescReference?ele.subSabaqDescReference:"",
        subSabaqDesc: ele.subSabaqDesc?ele.subSabaqDesc:"",
        subSabaqId:ele.subSabaqId,
        // subSabaqNo: ele.subSabaqNo,
        subSabaqNo: index + 1,

        subSabaqTitleAudio:typeof(ele.subSabaqTitleAudio)=="string" ? "not updated" : "updated",
        subSabaqDescAudio: typeof(ele.subSabaqDescAudio)=="string" ? "not updated" : "updated",
        subSabaqTitle: ele.subSabaqTitle?ele.subSabaqTitle:'',
      };
    });
    idEdit==="edit" ? payLoadFormData.append("subSabaq", JSON.stringify(subSabaqEdit)):payLoadFormData.append("subSabaq", JSON.stringify(subSabaq))


    let newSubaqAudio = [];
    newSubaqAudio.push(
      addNamaz.sabaqNameAudio 
    );
    newSubaqAudio.map((ele) =>
      payLoadFormData.append("sabaqNameAudio", ele?ele:"" )
    );

    let sabaqDescAudio1 = [];
    sabaqDescAudio1.push(
      addNamaz.sabaqDescAudio 
    );
    sabaqDescAudio1.map((ele) =>
      payLoadFormData.append("sabaqDescAudio", ele ?ele:"")
    );
    addNamaz.subSabaq.map((ele) =>
      payLoadFormData.append(
        "subSabaqDescAudio",
        ele.subSabaqDescAudio 
      )
    );

    addNamaz.subSabaq.map((ele) =>
      payLoadFormData.append(
        "subSabaqTitleAudio",
        ele.subSabaqTitleAudio ?  ele.subSabaqTitleAudio :""
      )
    );

    setLoader(true);
    // setIsLoading(true);
    idEdit === "edit"
      ? putMultipartWithAuthCallWithErrorResponse(
          ApiConfig.SUBAQUPDATE,
          payLoadFormData
        ).then((res) => {
          
          setSaveBotton(false)

          if (res.json && res.json?.success === true) {
            ToastMsg("success", res.json.message);
            umarahListApi();
 

         
          } else {
            ToastMsg("error", res.json.message);
          }
        })
        .catch((err) => {
          setLoader(false);

          ToastMsg("error", "something went wrong");
        })
      : multipartPostCallWithErrorResponse(
          ApiConfig.SUBAQADDALL,
          payLoadFormData
          // payLoadFormDataNew
        )
          .then((res) => {
         
            setSaveBotton(false)

            if (res.json && res.json?.success === true) {
              ToastMsg("success", res.json.message);
              umarahListApi();
   

            
            } else {
              ToastMsg("error", res.json.message);
            }
          })
          .catch((err) => {
            setLoader(false);

            ToastMsg("error", "something went wrong");
          });
  };
  //
  useEffect(() => {
    umarahListApi();
  }, []);
  const umarahListApi = () => {
    simpleGetCallWithErrorResponse(ApiConfig.SUBAQLIST + `?categoryId=${7}`)
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
 
          setUmrahList(res.json.data.allSabaq);
          setLsatSabaq(
            res.json?.data.allSabaq.length
          );
          // pagination();
          // ToastMsg("success", res.json.message);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  const umrahLisDeleteApi = () => {
    const body = {
      categoryId: 7,

      sabaqId: deleteId,
    };
    // setIsLoading(true);
    DeleteCallWithErrorResponse(
      ApiConfig.DELETEAPI,

      JSON.stringify({
        ...body,
      })
    )
      .then((res) => {
        setLoader(false);
        handleDeleteClose();
        if (res.json && res.json?.success === true) {
          // setSabaqList(res.json.data);
          umarahListApi();
          ToastMsg("success", res.json.message);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  const EditListApi = (id) => {

    // setIsLoading(true);
  
      
    

    simpleGetCallWithErrorResponse(ApiConfig.EDITALL + `?sabaqId=${id}`)
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          // ToastMsg("success", res.json.message);

          let data=res.json?.data
          setAddNamaz( {
           SabaqId:data?.SabaqId,
             sabaqName: data?.sabaqName?data?.sabaqName:"",
             sabaqDesc: data?.sabaqDesc?data?.sabaqDesc:"",
             sabaqDescAudio: data?.sabaqDescAudio? data?.sabaqDescAudio:"",
             sabaqNameAudio: data?.sabaqAudio? data?.sabaqAudio:"",
             sabaqDescReference: data?.sabaqDescReference?data?.sabaqDescReference:"",
             subSabaq: data && data.subSabaqs.map((ele,index)=>{
               return {
                 subSabaqTitle: ele.subSabaqTitle?ele.subSabaqTitle:"",
                 subSabaqTitleAudio: ele.titleAudioPath?ele.titleAudioPath:"",
                 subSabaqDesc: ele.subSabaqDesc? ele.subSabaqDesc:"",                
                 subSabaqDescAudio: ele.descAudioPath?ele.descAudioPath:"",
                 subSabaqTarjama: ele.tarjama?ele.tarjama:"",
                 subSabaqTarjamaAudio: ele.tarjamaAudioPath?ele.tarjamaAudioPath:"",
                 subSabaqDescReference:ele.descReference?ele.descReference:"",
                 subSabaqId:ele.subSabaqId,
                 subSabaqNo:ele.subSabaqNo?ele.subSabaqNo:index+1,
               }
             })
             
           })
          handleAddShow();
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  //testing function
  //view the
  const viewDetailshandle = (id) => {
    simpleGetCallWithErrorResponse(ApiConfig.EDITALL + `?sabaqId=${id}`)
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          setViewDetails(res.json.data);
          handleViewShow();
          // ToastMsg("success", res.json.message);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Umrah & Hajj</label>
        </div>
        <div className="add-btn">
          <button onClick={handleAddShow}>
            Add Sabaq
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path fill="white" d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" />
            </svg>
          </button>
        </div>
      </div>
      {/* ======================= START ADD ========================== */}
      <Modal
        show={add}
        // onHide={handleAddClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton         onHide={handleAddClose}
>
          {/* <Modal.Title>Add Umrah & Hajj</Modal.Title> */}
          <Modal.Title>{idEdit==="edit"?"Update Umrah & Hajj":"Add Umrah & Hajj"}</Modal.Title>

        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12 mb-3">
                <Form.Label>Sabaq Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={addNamaz?.sabaqName}
                  onChange={(e) =>
                    setAddNamaz({ ...addNamaz, sabaqName: e.target.value })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Sabaq Name
                </Form.Control.Feedback>
              </div>
         
                  {idEdit ? (
                <>
                  <div className="row d-flex">
                    <div className="col-md-12">
                      <label htmlFor="" className="key">
                        Sabaq Audio
                      </label>
                    </div>
                    <div
                      className={imageWidth === "100%" ? "d-none" : "col-md-6 "}
                    >
                      <Form.Label>
                        {typeof addNamaz?.sabaqNameAudio === "string"
                          ?addNamaz?.sabaqNameAudio.substring(
                            addNamaz?.sabaqNameAudio.length - 15,
                            addNamaz?.sabaqNameAudio.length
                            )
                          : ""}
                      </Form.Label>
                    </div>
                    <div className="col-md-12 mb-3 ">
                      <Form.Control
                        type="file"
                        accept="audio/mp3,audio/*;capture=microphone"
                        name="sabaqAudio"
                        style={{ width: imageWidth.sabaqAudio }}
                        onChange={(e) => {
                          setImageWidth({ ...imageWidth, sabaqAudio: "100%" });
                          setAddNamaz({
                            ...addNamaz,
                            sabaqNameAudio: e.target.files[0],
                          });
                        }}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Name
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-md-12 mb-3">
                  <Form.Label>Sabaq Audio</Form.Label>
                  <Form.Control
                    type="file"
                    accept="audio/mp3,audio/*;capture=microphone"
                    name="sabaqAudio"
                    //  / value={addSabaq?.sabaqAudio}
                    onChange={(e) =>
                      setAddNamaz({
                        ...addNamaz,
                        sabaqNameAudio: e.target.files[0],
                      })
                    }
                  />

                  <Form.Control.Feedback type="invalid">
                    Please Enter Sabaq Name
                  </Form.Control.Feedback>
                </div>
              )}
              <div className="col-md-12 mb-3">
                <Form.Label> Sabaq Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={addNamaz?.sabaqDesc}
                  onChange={(e) =>
                    setAddNamaz({ ...addNamaz, sabaqDesc: e.target.value })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Description.
                </Form.Control.Feedback>
              </div>
             
                  {idEdit ? (
                <>
                  <div className="row d-flex">
                    <div className="col-md-12">
                      <label htmlFor="" className="key">
                        Sabaq Description Audio
                      </label>
                    </div>
                    <div
                      className={imageWidth === "100%" ? "d-none" : "col-md-6 "}
                    >
                      <Form.Label>
                        {typeof addNamaz?.sabaqDescAudio === "string"
                          ? addNamaz?.sabaqDescAudio.substring(
                              addNamaz?.sabaqDescAudio.length - 15,
                              addNamaz?.sabaqDescAudio.length
                            )
                          : ""}
                      </Form.Label>
                    </div>
                    <div className="col-md-12 mb-3 ">
                      <Form.Control
                        type="file"
                        accept="audio/mp3,audio/*;capture=microphone"
                        name="sabaqDescAudio"
                        style={{ width: imageWidth.Uploade }}
                     
                        onChange={(e) => {
                          setImageWidth({ ...imageWidth, Uploade: "100%" });
                          setAddNamaz({
                            ...addNamaz,
                            sabaqDescAudio: e.target.files[0],
                          });
                        }}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Name
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-md-12 mb-3">
                  <Form.Label>Sabaq Description Audio</Form.Label>
                  <Form.Control
                    type="file"
                    accept="audio/mp3,audio/*;capture=microphone"
                    name="sabaqImagePath"
                    //  / value={addSabaq?.sabaqAudio}
                    onChange={(e) =>
                      setAddNamaz({
                        ...addNamaz,
                        sabaqDescAudio: e.target.files[0],
                      })
                    }
                  />

                  <Form.Control.Feedback type="invalid">
                    Please Enter Sabaq Name
                  </Form.Control.Feedback>
                </div>
              )}

              <div className="col-md-12 mb-3">
                <Form.Label>Sabaq Reference</Form.Label>
                <Form.Control
                  type="text"
                  value={addNamaz?.sabaqDescReference}
                  onChange={(e) =>
                    setAddNamaz({
                      ...addNamaz,
                      sabaqDescReference: e.target.value,
                    })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Reference
                </Form.Control.Feedback>
              </div>

              {addNamaz.subSabaq &&
                addNamaz.subSabaq.map((elementInArray, index) => {
                  return (
                    <div className="col-md-12 sub-group-main" key={"testkey"+index}>
                      <div className="row">
                        <h6 className="fw-bold">Sub Sabaq Details</h6>
                        <div className="col-md-12 mb-3">
                          <Form.Label>
                          Sub Sabaq Title <span>{index + 1}</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={elementInArray?.subSabaqTitle}
                            onChange={(e) => {
                              setAddNamaz({
                                ...addNamaz,
                                subSabaq: addNamaz.subSabaq.map(
                                  (item, innerIndex) => {
                                    return index === innerIndex
                                      ? {
                                          ...item,
                                          subSabaqTitle: e.target.value,
                                        }
                                      : item;
                                  }
                                ),
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Sabaq Name
                          </Form.Control.Feedback>
                        </div>
                        {/* <div className="col-md-6 mb-3">
                          <Form.Label>Title Audio</Form.Label>
                          <Form.Control
                            type="file"
                            value={elementInArray?.subSabaqTitleAudio}
                            onChange={(e) => {
                              setAddNamaz({
                                ...addNamaz,
                                subSabaq: addNamaz.subSabaq.map(
                                  (item, innerIndex) => {
                                    return index === innerIndex
                                      ? {
                                          ...item,
                                          subSabaqTitleAudio: e.target.files[0],
                                        }
                                      : item;
                                  }
                                ),
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Sabaq Name
                          </Form.Control.Feedback>
                        </div> */}

{idEdit ? (
                          <>
                            <div className="row d-flex">
                              <div className="col-md-12">
                                <label htmlFor="" className="key">
                                  Sub Sabaq Title Audio
                                </label>
                              </div>
                              <div
                                className={
                                  imageWidth === "100%" ? "d-none" : "col-md-6 "
                                }
                              >
                                <Form.Label>
                                  {typeof elementInArray?.subSabaqTitleAudio ===
                                  "string"
                                    ? elementInArray?.subSabaqTitleAudio.substring(
                                        elementInArray?.subSabaqTitleAudio
                                          .length - 15,
                                        elementInArray?.subSabaqTitleAudio
                                          .length
                                      )
                                    : ""}
                                </Form.Label>
                              </div>
                              <div className="col-md-12 mb-3 ">
                                <Form.Control
                                  type="file"
                                  accept="audio/mp3,audio/*;capture=microphone"
                                  name="subSabaqTitleAudio"
                                  style={{ width: imageWidth.subSabaqTitle }}
                                  //  value={addSabaq?.sabaqAudio}
                                  // onChange={(e) => {
                                  //   setImageWidth({ ...imageWidth, Description: "100%" });
                                  //   setAddSabaq({
                                  //     ...addSabaq,
                                  //     sabaqAudio: e.target.files[0],
                                  //   });
                                  // }}
                                  onChange={(e) => {
                                    setImageWidth({
                                      ...imageWidth,
                                      subSabaqTitle: "100%",
                                    });
                                    setAddNamaz({
                                      ...addNamaz,
                                      subSabaq: addNamaz.subSabaq.map(
                                        (item, innerIndex) => {
                                          return index === innerIndex
                                            ? {
                                                ...item,
                                                subSabaqTitleAudio:
                                                  e.target.files[0],
                                              }
                                            : item;
                                        }
                                      ),
                                    });
                                  }}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Sabaq Name
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-12 mb-3">
                            <Form.Label>Sub Sabaq Title Audio</Form.Label>
                            <Form.Control
                              type="file"
                              accept="audio/mp3,audio/*;capture=microphone"
                              name="sabaqImagePath"
                              //  / value={addSabaq?.sabaqAudio}
                              onChange={(e) => {
                                setAddNamaz({
                                  ...addNamaz,
                                  subSabaq: addNamaz.subSabaq.map(
                                    (item, innerIndex) => {
                                      return index === innerIndex
                                        ? {
                                            ...item,
                                            subSabaqTitleAudio:
                                              e.target.files[0],
                                          }
                                        : item;
                                    }
                                  ),
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Sabaq Name
                            </Form.Control.Feedback>
                          </div>
                        )}
                        <div className="col-md-12 mb-3">
                          <Form.Label>Sub Sabaq Description</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={elementInArray?.subSabaqDesc}
                            onChange={(e) => {
                              setAddNamaz({
                                ...addNamaz,
                                subSabaq: addNamaz.subSabaq.map(
                                  (item, innerIndex) => {
                                    return index === innerIndex
                                      ? {
                                          ...item,
                                          subSabaqDesc: e.target.value,
                                        }
                                      : item;
                                  }
                                ),
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Aqaaid Description.
                          </Form.Control.Feedback>
                        </div>
                        {/* <div className="col-md-12 mb-3">
                          <Form.Label>Description Audio</Form.Label>
                          <Form.Control
                            type="file"
                            value={elementInArray?.subSabaqDescAudio}
                            onChange={(e) => {
                              setAddNamaz({
                                ...addNamaz,
                                subSabaq: addNamaz.subSabaq.map(
                                  (item, innerIndex) => {
                                    return index === innerIndex
                                      ? {
                                          ...item,
                                          subSabaqDescAudio: e.target.files[0],
                                        }
                                      : item;
                                  }
                                ),
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Sabaq Name
                          </Form.Control.Feedback>
                        </div> */}

<>
                          {idEdit ? (
                            <>
                              <div className="row d-flex">
                                <div className="col-md-12">
                                  <label htmlFor="" className="key">
                                 Sub Sabaq Description Audio
                                  </label>
                                </div>
                                <div
                                  className={
                                    imageWidth === "100%"
                                      ? "d-none"
                                      : "col-md-6 "
                                  }
                                >
                                  <Form.Label>
                                    {typeof elementInArray?.subSabaqDescAudio ===
                                    "string"
                                      ? elementInArray?.subSabaqDescAudio.substring(
                                        elementInArray?.subSabaqDescAudio.length -
                                            15,
                                         elementInArray?.subSabaqDescAudio.length
                                        )
                                      : ""}
                                  </Form.Label>
                                </div>
                                <div className="col-md-12 mb-3 ">
                                  <Form.Control
                                    type="file"
                                    accept="audio/mp3,audio/*;capture=microphone"
                                    name="sabaqImagePath"
                                    style={{ width: imageWidth.Description }}
                                  
                                    onChange={(e) => {
                                      setImageWidth({
                                        ...imageWidth,
                                        Description: "100%",
                                      });
                                      setAddNamaz({
                                        ...addNamaz,
                                        subSabaq: addNamaz.subSabaq.map(
                                          (item, innerIndex) => {
                                            return index === innerIndex
                                              ? {
                                                  ...item,
                                                  subSabaqDescAudio:
                                                    e.target.files[0],
                                                }
                                              : item;
                                          }
                                        ),
                                      });
                                    }}
                                  />

                                  <Form.Control.Feedback type="invalid">
                                    Please Enter Sabaq Name
                                  </Form.Control.Feedback>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="col-md-12 mb-3">
                              <Form.Label>Sub Sabaq Description Audio</Form.Label>
                              <Form.Control
                                type="file"
                                accept="audio/mp3,audio/*;capture=microphone"
                                name="sabaqImagePath"
                                //  / value={addSabaq?.sabaqAudio}
                                onChange={(e) => {
                                  setAddNamaz({
                                    ...addNamaz,
                                    subSabaq: addNamaz.subSabaq.map(
                                      (item, innerIndex) => {
                                        return index === innerIndex
                                          ? {
                                              ...item,
                                              subSabaqDescAudio: e.target.files[0],
                                            }
                                          : item;
                                      }
                                    ),
                                  });
                                }}
                              />

                              <Form.Control.Feedback type="invalid">
                                Please Enter Sabaq Name
                              </Form.Control.Feedback>
                            </div>
                          )}
                        </>
                        
                        <div className="col-md-12 mb-3">
                          <Form.Label>Sub Sabq Reference</Form.Label>
                          <Form.Control
                            type="text"
                            value={elementInArray?.subSabaqDescReference}
                            onChange={(e) => {
                              setAddNamaz({
                                ...addNamaz,
                                subSabaq: addNamaz.subSabaq.map(
                                  (item, innerIndex) => {
                                    return index === innerIndex
                                      ? {
                                          ...item,
                                          subSabaqDescReference: e.target.value,
                                        }
                                      : item;
                                  }
                                ),
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Reference
                          </Form.Control.Feedback>
                        </div>
                      </div>
                      <div className="text-end">
                        <Link
                          // className="btn btn-danger"
                          // onClick={() => setAddNewGroup(AddNewGroup - 1)}
                          className={
                            addNamaz.subSabaq.length > 1
                              ? "btn btn-danger"
                              : "btn btn-secondary invisible"
                          }
                          onClick={() => {
                            if (addNamaz.subSabaq.length > 1) {
                              // addNamaz.subSabaq.splice(index, 1);
                              const copySubDeatails = [...addNamaz.subSabaq];
                              copySubDeatails.splice(index, 1);
                              setAddNamaz({
                                ...addNamaz,
                                subSabaq: copySubDeatails,
                              });
                            }

                            // const copySubDeatails = [...addSabaq.subsabaqDetails];
                            // copySubDeatails.sli
                          }}
                        >
                          Remove
                        </Link>
                      </div>
                    </div>
                  );
                })}

              <Link
                to="#"
                className={
                  // idEdit
                  //   ? "add-newSabaq-btn invisible btn btn-secondary pe-none"
                  //   : 
                    "add-newSabaq-btn btn btn-primary"
                }
                // onClick={() => setAddNewGroup(AddNewGroup + 1)}

                onClick={() => {
                  let namaz = [
                    ...addNamaz.subSabaq,

                    {
                      subSabaqTitle: "",
                      subSabaqTitleAudio: "",
                      subSabaqDesc: "",
                      subSabaqDescAudio: "",
                      subSabaqDescReference: "",
                    },
                  ];
                  setAddNamaz({
                    ...addNamaz,
                    subSabaq: namaz,
                  });
                }}
              >
                + Add Sub Sabaq Details
              </Link>
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <button className="me-2" type="submit" disabled={saveBotton}>
                {idEdit === "edit" ? "update" : "Save"}
              </button>
              <Link
                to="#"
                className=" bg-danger"
                onClick={() => {
                  setAddNamaz({
                    sabaqName: "",
                    sabaqDesc: "",
                    sabaqDescAudio: "",
                    sabaqNameAudio: "",
                    sabaqDescReference: "",
                    subSabaq: [
                      {
                        subSabaqTitle: "",
                        subSabaqTitleAudio: "",
                        subSabaqDesc: "",
                        subSabaqDescAudio: "",
                        subSabaqDescReference: "",
                      },
                    ],
                  });
                  handleAddClose();
                  setIdEdit("");
                }}
              >
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END ADD ========================== */}

      {/* ======================= DELETE ========================== */}

      <Modal
        show={deleteModal}
        onHide={handleDeleteClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">Are you sure you want to delete this item?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button
              className="me-2 bg-danger"
              type="submit"
              onClick={()=>{
                handleDeleteClose()
                umrahLisDeleteApi()}}
            >
              Delete
            </button>
            <Link to="#" className="" onClick={handleDeleteClose}>
              Cancel
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
      {/* ======================= END DELETE ========================== */}

      {/* ======================= VIEW ========================== */}
      <Modal
        show={viewModal}
        onHide={handleViewClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Umrah & Hajj Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <div className="col-md-12 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Sabaq No.
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewDetails?.SabaqId}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Sabaq Name
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                  {viewDetails?.sabaqName}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="mb-2">
                  <label htmlFor="" className="key">
                    Sabaq Audio
                  </label>
                </div>
                <div>
                  <audio controls style={{ width: "100%", height: "38px" }}>
                    <source src= {viewDetails?.sabaqAudio} type="audio/ogg" />
                    <source src= {viewDetails?.sabaqAudio}type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              </div>

              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Sabaq Description
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                  {viewDetails?.sabaqDesc}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="mb-2">
                  <label htmlFor="" className="key">
                    Sabaq Description Audio
                  </label>
                </div>
                <div>
                  <audio controls style={{ width: "100%", height: "38px" }}>
                    <source src=  {viewDetails?.sabaqDescAudio} type="audio/ogg" />
                    <source src=  {viewDetails?.sabaqDescAudio} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              </div>

              <div className="col-md-12 mb-3">
                <div>
                  <label htmlFor="" className="key">
                   Sabaq Reference
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                  {viewDetails?.sabaqDescReference}
                  </label>
                </div>
              </div>

              <h5 className="fw-bold">Sub Sabaq Details</h5>
              {viewDetails && viewDetails.subSabaqs && viewDetails.subSabaqs.length>0 &&
              viewDetails.subSabaqs.map((ele,index)=>{
                return(
                  <div className="sub-group-main row" key={"testkeyqwe"+index}>
                  <div className="col-md-6 mb-3">
                    <div>
                      <label htmlFor="" className="key">
                      Sub Sabaq Title {ele.subSabaqNo}
                      </label>
                    </div>
                    <div>
                      <label htmlFor="" className="value">
                    {ele?.subSabaqTitle}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="mb-2">
                      <label htmlFor="" className="key">
                      Sub Sabaq Title Audio
                      </label>
                    </div>
                    <div>
                      <audio controls style={{ width: "100%", height: "38px" }}>
                        <source src= {ele?.titleAudioPath} type="audio/ogg" />
                        <source src= {ele?.titleAudioPath}type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                  </div>
  
                  <div className="col-md-6 mb-3">
                    <div>
                      <label htmlFor="" className="key">
                      Sub Sabaq Description
                      </label>
                    </div>
                    <div>
                      <label htmlFor="" className="value">
                      {ele?.subSabaqDesc}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="mb-2">
                      <label htmlFor="" className="key">
                      Sub Sabaq Description Audio
                      </label>
                    </div>
                    <div>
                      <audio controls style={{ width: "100%", height: "38px" }}>
                        <source src=  {ele?.descAudioPath} type="audio/ogg" />
                        <source src=  {ele?.descAudioPath} type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div>
                      <label htmlFor="" className="key">
                      Sub Sabaq Reference
                      </label>
                    </div>
                    <div>
                      <label htmlFor="" className="value">
                       {ele.descReference}
                      </label>
                    </div>
                  </div>
                </div>
                )
              })}
             
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <Link to="#" className="bg-danger" onClick={handleViewClose}>
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END VIEW ========================== */}
      {loader?<FormLoader/>:<div className="table-wrapper">
      <h6 >
            Total Records : {umrahList.length}
          </h6>
          <Table className="display" striped bordered hover size="lg">

          <thead>
            <tr>
              <th>Sabaq No.</th>
              <th style={{ textAlign: "left" }}>Sabaq List</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {umrahList && umrahList.length > 0
              ? umrahList.slice(startIndex, endIndex).map((ele, index) => {
                  return (
                    <tr key={"umrah"+index}>
                                                                    <td>{index + 1 + startIndex}</td>

                      <td style={{ textAlign: "left" }}>{ele.sabaqName}</td>
                      <td className="action">
                        <Link
                          to="#"
                          className="icon edit"
                          onClick={() => {
                            // handleAddShow();
                            setIdEdit("edit");
                            setImageWidth({
                              ...imageWidth,
                              sabaqAudio: "117px",
                              Uploade: "117px",
                              Tarjama: "117px",
                              Description: "117px",
                              subSabaqTitle: "117px",
                            });
                            EditListApi(ele.sabaqId);
                          }}
                        >
                          <img src={ic_edit} alt="" />
                        </Link>
                        <Link
                          to="#"
                          className="icon view"
                          onClick={() => viewDetailshandle(ele.sabaqId)}
                        >
                          <img src={ic_eyes} alt="" />
                        </Link>
                        <Link
                          to="#"
                          className="icon delete"
                          onClick={() => {
                            handleDeleteShow();
                            setDeleteId(ele.sabaqId);
                          }}
                        >
                          <img src={ic_delete} alt="" />
                        </Link>
                      </td>
                    </tr>
                  );
                })
              : ""}
          </tbody>
          </Table>
   {   umrahList && umrahList.length > 0
              ?   <Pagination
            changePage={changePage}
            pageCount={pageCount}
            currentPage={currentPage}

          
          />: (
            <div>
              <h5 style={{ color: "red", textAlign: "center" }}>
                {" "}
                Records Not Found
              </h5>
            </div>
          )}
      </div>}
    </main>
  );
};

export default UmrahHajj;
